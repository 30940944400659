<script setup lang="ts">
import type { ColorSuggestions, ShadowProps } from '@gem/common';
import {
  AnglePicker,
  InputComboLayout,
  InputUnitWithDropdown,
  OptionValue,
  SettingLayout,
} from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
import ColorPicker from '../color-picker/ColorPicker.vue';
import type { ShadowSettingProps } from './types';
import { useBoxShadowSettings } from './useBoxShadowSetting';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<ShadowSettingProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: ShadowProps): void;
  (e: 'controlOnChange', value?: ShadowProps): void;
  (e: 'updateColorSuggestion', value?: ColorSuggestions): void;
}>();

const {
  isTextShadow,
  shadowValue,
  displayComboIcon,
  displayComboColor,
  displayComboValue,
  shadowPresets,
  isCustomShadow,
  handleSetDefaultValue,
  handleClearShadow,
  handleUpdateSetting,
  handleUpdateSuggestionColor,
} = useBoxShadowSettings(props, emit);
</script>
<template>
  <div>
    <InputComboLayout
      :id="id"
      :placeholder="t('Add...')"
      :is-parent="true"
      :label="`${isTextShadow ? t('Text shadow') : t('Shadow')}`"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayComboValue"
      :combo-color="displayComboColor"
      :combo-icon="(displayComboIcon as any)"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClearShadow">
      <SettingLayout v-if="!isTextShadow" :label-with-lang="t('Type')" layout="vertical">
        <template #control>
          <OptionValue
            :id="'style'"
            background-class="bg-dark-400"
            :value="shadowValue?.type"
            :options="shadowPresets as any"
            @control-change="(value: string) => handleUpdateSetting('type', 'controlChange', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isCustomShadow" :label-with-lang="t('Direction')" layout="horizontal" align="top">
        <template #control>
          <AnglePicker
            id="angle"
            :value="shadowValue?.angle"
            @control-change="(_: any, value: string) => handleUpdateSetting('angle', 'controlChange', value)"
            @control-on-change="(_: any, value: string) => handleUpdateSetting('angle', 'controlOnChange', value)">
          </AnglePicker>
        </template>
      </SettingLayout>
      <SettingLayout :label-with-lang="t('Color')" layout="horizontal">
        <template #control>
          <ColorPicker
            v-bind="props"
            id="color"
            :value="shadowValue?.color"
            :is-child="true"
            :level="(level || 0) + 1"
            :combo-i-d="`${comboID}-color`"
            :combo-parent-i-d="comboID || ''"
            :combo-root-parent-i-d="comboRootParentID || ''"
            :compo-default-value="props.compoDefaultValue?.color"
            @save-my-colors="handleUpdateSuggestionColor"
            @control-change="(value: any) => handleUpdateSetting('color', 'controlChange', value)"
            @control-on-change="(value: any) => handleUpdateSetting('color', 'controlOnChange', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isCustomShadow" :label-with-lang="t('Distance')" layout="horizontal">
        <template #control>
          <InputUnitWithDropdown
            :id="'distance'"
            :value="shadowValue?.distance"
            :units="['px']"
            :min="0"
            :disable-decimal="true"
            fallback-value="0px"
            @control-change="(value) => handleUpdateSetting('distance', 'controlChange', value)"
            @control-on-change="(value) => handleUpdateSetting('distance', 'controlOnChange', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isCustomShadow" :label-with-lang="t('Blur')" layout="horizontal">
        <template #control>
          <InputUnitWithDropdown
            :id="'blur'"
            :value="shadowValue?.blur"
            :units="['px']"
            :min="0"
            :disable-decimal="true"
            fallback-value="0px"
            @control-change="(value) => handleUpdateSetting('blur', 'controlChange', value)"
            @control-on-change="(value) => handleUpdateSetting('blur', 'controlOnChange', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isCustomShadow && type !== 'text-shadow'" :label-with-lang="t('Size')" layout="horizontal">
        <template #control>
          <InputUnitWithDropdown
            :id="'spread'"
            :value="shadowValue?.spread"
            :units="['px']"
            :min="0"
            :disable-decimal="true"
            fallback-value="0px"
            @control-change="(value) => handleUpdateSetting('spread', 'controlChange', value)"
            @control-on-change="(value) => handleUpdateSetting('spread', 'controlOnChange', value)" />
        </template>
      </SettingLayout>
    </InputComboLayout>
  </div>
</template>
