<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'ok'): void;
}>();
</script>

<template>
  <div class="bg-dark-400 rounded-b-xxl absolute bottom-0 right-0 z-[9999] flex flex-col gap-16 p-16">
    <div class="flex flex-col">
      <p class="text-12 text-text-dark-500 font-medium leading-5">{{ t('Delete icon') }}</p>
      <p class="text-12 text-text-dark-300 leading-5">
        {{ t("Are you sure you want to delete this icon? This action can't be undone.") }}
      </p>
    </div>
    <div class="flex justify-end gap-8">
      <GButton type="secondary" size="semi-medium" @click="() => emit('cancel')">{{ t('Cancel') }}</GButton
      ><GButton type="danger" size="semi-medium" @click="() => emit('ok')">{{ t('Delete') }}</GButton>
    </div>
  </div>
</template>
