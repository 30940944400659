<script setup lang="ts">
import { cn } from '@gem/common';
import { getLabelWithLang } from '@gem/i18n';
import { GSvg } from '@gem/icons';
import { GTooltip } from '@gem/uikit-v2';
import type { LayoutSelectorType } from '../types';

const emit = defineEmits<{
  (e: 'changeLayout', value: string): void;
}>();
const props = withDefaults(
  defineProps<Pick<LayoutSelectorType, 'options' | 'itemPerRow' | 'value' | 'enableItemOpacity'>>(),
  {
    itemPerRow: 3,
    enableItemOpacity: false,
  },
);
const handleChangeLayout = (value: string) => {
  if (isSelected(value)) return;
  emit('changeLayout', value);
};

const isSelected = (value: string) => {
  return value === props.value;
};
</script>

<template>
  <div class="grid w-full gap-8" :class="cn(`grid-cols-${itemPerRow}`)">
    <div
      v-for="item in options"
      :key="item.value"
      class="group/layout grid max-w-full cursor-pointer rounded-xl border border-transparent"
      :class="{
        'border-primary-300': isSelected(item.value),
      }"
      @click="handleChangeLayout(item.value)">
      <GTooltip placement="top" strategy="fixed" display-type="block">
        <div
          class="bg-dark-400 hover:bg-dark-250 flex h-[80px] items-center justify-center overflow-hidden rounded-xl p-[12px] hover:opacity-100"
          :class="
            cn([
              itemPerRow === 3 ? 'h-[52px]' : 'h-[64px]',
              enableItemOpacity ? 'opacity-50' : '',
              item.noPadding === 'x' ? 'px-0' : '',
              item.noPadding === 'y' ? 'py-0' : '',
              item.noPadding === 'all' ? 'p-0' : '',
              item.value == value ? '!opacity-100' : '',
            ])
          ">
          <GSvg :name="item.iconName" />
        </div>
        <template #content>
          {{ getLabelWithLang(item.label) }}
        </template>
      </GTooltip>
    </div>
  </div>
</template>
