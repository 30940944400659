<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { SUPPORT_CHANGE_SOURCE_FONTS } from '../constants';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  label: string;
}>();

defineEmits<{
  (e: 'handle-change-source'): void;
}>();

const hasChangeSource = (label: string) => {
  return SUPPORT_CHANGE_SOURCE_FONTS.includes(label);
};
</script>

<template>
  <div class="mt-16 mb-4 flex justify-between">
    <div class="flex items-center">
      <p class="text-12 text-text-dark-300 leading-18 font-normal">{{ label }}</p>
    </div>
    <GButton v-if="hasChangeSource(label)" type="link" size="small" @click="$emit('handle-change-source')">{{
      t('Change source')
    }}</GButton>
  </div>
</template>
