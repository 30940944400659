<script setup lang="ts">
import { cn } from '@gem/common';
import { SettingTitle } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
import { GTooltip } from '@gem/uikit';
import { computed, useSlots, type StyleValue } from 'vue';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import type { LabelVariant, SettingUIHelpType } from '../types';
import { LabelWithLang } from '../types';
const { t } = useI18n({ useScope: 'global' });

const props = withDefaults(
  defineProps<{
    layout?: 'vertical' | 'horizontal';
    isFullWidth?: boolean;
    disabled?: boolean;
    styles?: StyleValue;
    isControlAutoWidth?: boolean;
    labelWithLang?: LabelWithLang;
    label?: string;
    labelVariant?: LabelVariant;
    labelShow?: boolean;
    showDevices?: boolean;
    help?: SettingUIHelpType;
    align?: 'top' | 'center';
    tooltipMessage?: string;
    tooltipPosition?: 'left' | 'right';
  }>(),
  {
    labelShow: true,
    isFullWidth: true,
    align: 'center',
    labelVariant: 'secondary',
  },
);

const slots = useSlots();
const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const tooltipClass = computed(() => {
  const position = props.tooltipPosition;
  if (position === 'left') return '-translate-x-1/2 left-16';
  if (position === 'right') return 'translate-x-1/2 right-16';

  return '';
});
</script>

<template>
  <div
    data-test="SettingLayout"
    class="relative flex w-full justify-between"
    :class="
      cn([
        layout === 'vertical' ? 'flex-col justify-start gap-12' : 'items-center gap-4',
        !isFullWidth ? 'pl-24' : '',
        align === 'top' ? 'items-start' : '',
      ])
    "
    :style="styles">
    <div v-if="disabled" class="rounded-12 absolute top-0 right-0 z-[100] h-full w-[140px] cursor-not-allowed">
      <GTooltip
        placement="top"
        class="relative !block h-full w-full"
        :class="tooltipClass"
        content-class="max-w-[200px] whitespace-normal"
        :is-teleport="true">
        <template #content>
          {{ tooltipMessage || t('Settings cannot be used at this time') }}
        </template>
      </GTooltip>
    </div>
    <slot v-if="slots.locked" name="locked"></slot>
    <template v-else>
      <div
        v-if="!!(labelWithLang as LabelWithLang)?.en || !!labelWithLang || !!label"
        :class="cn([align === 'top' ? 'mt-[2px]' : ''])">
        <SettingTitle
          v-if="labelShow"
          :label-with-lang="labelWithLang"
          :label="label"
          :help="help"
          :variant="labelVariant"
          :show-devices="showDevices"
          :current-device="currentDevice" />
      </div>
      <div
        :class="
          cn([
            layout === 'vertical' ? 'w-full' : 'w-full max-w-[140px] flex-shrink-0',
            isControlAutoWidth ? '!w-auto' : '',
          ])
        ">
        <slot name="control"></slot>
      </div>
    </template>
  </div>
</template>
