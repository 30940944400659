<script setup lang="ts">
import type { ScreenType } from '@gem/common';
import type { ActionSettingInput } from '@gem/element-setting-ui';
import { ColumnWithSlider, InputComboLayout, SettingLayout, SettingTitle } from '@gem/element-setting-ui';
import { computed } from 'vue';
import type { DisplayType, RowLayoutProps } from '../types';
import ColumnWidth from './ColumnWidth.vue';
import LayoutRecommend from './LayoutRecommend.vue';

import { useI18n } from '@gem/i18n';
import { useRowLayoutSettingsUpdate } from '../hooks/useRowLayoutSettingsUpdate';
import ColumnOrder from './ColumnOrder.vue';

const props = withDefaults(defineProps<RowLayoutProps>(), {
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'change-layout', value: number, device: ScreenType): void;
  (e: 'remove-item', id: string, device: ScreenType): void;
  (e: 'copy-item', id: string, device: ScreenType): void;
  (e: 'change-display', value: DisplayType, device: ScreenType): void;
  (e: 'change-gap', type: 'change' | 'onChange', value: string | undefined, device: ScreenType): void;
  (e: 'change-cols', type: 'change' | 'onChange', value: number[] | undefined, device: ScreenType): void;
  (e: 're-order', input: ActionSettingInput[]): void;
  (e: 're-order-post-purchase', data: { id?: string; title?: string }[]): void;
}>();

const { t } = useI18n({ useScope: 'global' });
const { orderItems, maxColumn, isShowColumnWithSlider, getValueByDevice, getReOrderValue } =
  useRowLayoutSettingsUpdate(props);

const value = computed(() => getValueByDevice(props.currentScreen));

const showDevices = computed(() => !props.hiddenDevices);

const handleChangeLayout = (newCols: number) => {
  emit('change-layout', newCols, props.currentScreen);
};

const handleRemoveItem = (uid: string) => {
  emit('remove-item', uid, props.currentScreen);
};

const handleCopyItem = (uid: string) => {
  emit('copy-item', uid, props.currentScreen);
};

const handleReOrder = (data: { id?: string; title?: string }[]) => {
  emit('re-order', getReOrderValue(data, props.currentScreen));
  if (!props.isSupportResponsiveOrder) emit('re-order-post-purchase', data);
};

const handleChangeDisplay = (displayType: DisplayType) => {
  emit('change-display', displayType, props.currentScreen);
};

const handleChangeCols = (type: 'change' | 'onChange', value?: number[]) => {
  emit('change-cols', type, value, props.currentScreen);
};
</script>

<template>
  <div class="flex flex-col gap-16">
    <div class="border-dark-300 flex flex-col gap-16 border-b pb-16">
      <SettingLayout
        :show-devices="showDevices"
        layout="vertical"
        label-variant="primary"
        :label-with-lang="t('Layout')">
        <template #control>
          <LayoutRecommend :value="value" :device="currentScreen" :max-col="maxColumn" @change="handleChangeLayout"
        /></template>
      </SettingLayout>
    </div>
    <div class="border-dark-300 flex flex-col gap-16 border-b pb-16">
      <SettingLayout
        v-if="isShowColumnWithSlider"
        :show-devices="showDevices"
        layout="vertical"
        label-variant="primary"
        :label-with-lang="t('Column width')">
        <template #control>
          <ColumnWidth :display-type="value?.display" @control-change="handleChangeDisplay" />
        </template>
      </SettingLayout>
      <ColumnWithSlider
        v-if="value?.display == 'fill'"
        :disabled="value?.display !== 'fill'"
        :cols="value?.cols"
        @control-change="(value: number[]) => handleChangeCols('change', value)"
        @control-on-change="(value: number[]) => handleChangeCols('onChange', value)" />
    </div>
    <div class="flex flex-col gap-16">
      <SettingTitle :show-devices="showDevices" label-variant="primary" :label="t('Columns management')" />
      <SettingLayout :label-with-lang="t('Order')">
        <template #control>
          <InputComboLayout
            placeholder="Add..."
            :is-parent="true"
            :show-label="false"
            :is-full-width="true"
            :is-hide-clear="true"
            :label="t('Columns management')"
            :combo-icon="'polaris-list-bulleted'"
            :content-display="`${orderItems.length} ${orderItems.length > 1 ? 'cols' : 'col'}`"
            :level="level || 1"
            :combo-i-d="id"
            :combo-parent-i-d="id"
            :combo-root-parent-i-d="id">
            <ColumnOrder
              :childrens="childrens"
              :order-items="orderItems"
              :current-screen="currentScreen"
              :is-support-responsive-order="isSupportResponsiveOrder"
              @copy-item="handleCopyItem"
              @remove-item="handleRemoveItem"
              @on-re-order="handleReOrder" />
          </InputComboLayout>
        </template>
      </SettingLayout>
    </div>
  </div>
</template>
