<script setup lang="ts">
import { GIcon } from '@gem/icons';
import { GButton } from '@gem/uikit-v2';
import { ref } from 'vue';
import type { GalleryImageItem } from '../../types';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });

type Props = {
  item?: GalleryImageItem;
  isUploader?: boolean;
  isDeletedSection?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isUploader: false,
  isDeletedSection: false,
});

const emit = defineEmits<{
  (e: 'upload', event: Event): void;
  (e: 'delete', id: string): void;
  (e: 'restore', id: string): void;
  (e: 'setLoaded', id: string): void;
  (e: 'forceDelete', id: string): void;
  (e: 'click', item: GalleryImageItem): void;
}>();

const isConfirmingDelete = ref(false);

const handleImageClick = () => {
  if (props.item && !isConfirmingDelete.value) {
    emit('click', props.item);
  }
};

const handleDelete = () => {
  if (!props.item) return;
  isConfirmingDelete.value = true;
};

const handleConfirmDelete = () => {
  if (!props.item) return;
  emit('delete', props.item.id);
  isConfirmingDelete.value = false;
};

const handleForceDelete = () => {
  if (!props.item) return;
  emit('forceDelete', props.item.id);
};

const handleRestore = () => {
  if (!props.item) return;
  emit('restore', props.item.id);
};

const handleCancelDelete = () => {
  isConfirmingDelete.value = false;
};

const handleImageLoaded = () => {
  if (!props.item) return;
  emit('setLoaded', props.item.id);
};
</script>

<template>
  <div
    v-if="isUploader"
    data-test="editor-control-modal-button-upload"
    class="group/plus !border-light-500 relative flex w-full cursor-pointer items-center justify-around overflow-hidden !rounded-[12px] bg-white">
    <input
      class="z-2 absolute m-0 h-full w-full cursor-pointer rounded bg-black/5 p-0 opacity-0 outline-none"
      type="file"
      accept="image/*"
      @change="(e) => emit('upload', e)" />
    <GIcon class="text-text-light-100 group-hover/plus:text-text-light-500" name="polaris-plus" :size="30" />
  </div>

  <div
    v-else
    :key="item?.id"
    data-test="editor-control-modal-gallery-item"
    class="group relative flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-xl bg-white"
    @click="handleImageClick">
    <div
      class="flex h-full w-full transform-gpu flex-col items-center justify-center overflow-hidden rounded-md after:block after:pb-[100%] after:content-['']">
      <img
        v-if="item"
        :alt="t('image')"
        class="absolute max-h-full max-w-full object-fill"
        :src="item.previewUrl"
        @load="handleImageLoaded" />
    </div>

    <GalleryItemLoading v-if="item?.isLoading" />

    <!-- Overlay Content -->
    <div v-if="item" class="overlay-background absolute top-0 left-0 hidden h-full w-full rounded group-hover:block">
      <template v-if="isConfirmingDelete">
        <div class="flex flex-col" @click.stop>
          <div class="text-14 mt-12 ml-12 text-white">{{ t('Delete Image?') }}</div>
          <p v-if="item.size" class="text-12 text-text-dark-300 mt-4 ml-12 leading-5">
            {{ (item.size / 1024).toFixed(2) }} kb
          </p>
          <div class="absolute right-12 bottom-12 flex gap-10">
            <GButton
              type="ghost"
              data-test="editor-control-modal-gallery-item-delete-cancel"
              size="small"
              @click="handleCancelDelete">
              {{ t('Cancel') }}
            </GButton>
            <GButton
              type="danger"
              data-test="editor-control-modal-gallery-item-delete-confirm"
              size="small"
              @click="handleConfirmDelete">
              {{ t('Delete') }}
            </GButton>
          </div>
        </div>
      </template>

      <template v-else-if="!isDeletedSection">
        <div class="height-[105px] m-auto text-ellipsis px-12 pt-12">
          <p class="line-clamp-2 text-light-100 text-12 mx-auto mb-1 break-all font-semibold">
            {{ item.name }}
          </p>
          <p v-if="item.size" class="text-text-dark-300 text-12 mx-auto text-ellipsis leading-5">
            {{ (item.size / 1024).toFixed(2) }} kb
          </p>
        </div>
        <div class="absolute bottom-10 right-10">
          <GButton
            size="medium"
            type="dangerGhost"
            :only-icon="'polaris-delete'"
            data-test="editor-control-modal-gallery-item-delete"
            @click.stop="handleDelete" />
        </div>
      </template>

      <!-- Deleted Section View -->
      <template v-else>
        <div class="height-[105px] m-auto text-ellipsis px-12 pt-12">
          <p class="line-clamp-2 text-light-100 text-12 mx-auto mb-1 break-all font-semibold">
            {{ item.name }}
          </p>
          <p v-if="item.size" class="text-text-dark-300 text-12 mx-auto text-ellipsis leading-5">
            {{ (item.size / 1024).toFixed(2) }} kb
          </p>
        </div>
        <div class="absolute right-12 bottom-12 flex gap-10">
          <GButton
            type="ghost"
            data-test="editor-control-modal-gallery-item-restore"
            size="small"
            @click="handleRestore">
            {{ t('Restore') }}
          </GButton>
          <GButton
            type="danger"
            data-test="editor-control-modal-gallery-item-force-delete"
            size="small"
            @click="handleForceDelete">
            {{ t('Delete') }}
          </GButton>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay-background {
  background-color: #252525d9;
}
</style>
