<script setup lang="ts">
import { useI18n } from '@gem/i18n';
import { GButton } from '@gem/uikit-v2';

defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="non-draggable flex h-full items-center gap-4 hover:cursor-pointer">
    <GButton type="tertiary" size="normal" @click.stop="$emit('cancel')"> {{ t('Cancel') }} </GButton>
    <GButton type="danger" size="normal" @click.stop="$emit('confirm')"> {{ t('Delete') }} </GButton>
  </div>
</template>
