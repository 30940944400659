import { computed, ref } from 'vue';
import type { Lang, SettingUIToggleSettings } from '../types';

export const useGroupToggleSettingUpdate = (
  props: { data?: SettingUIToggleSettings[] },
  emit: any,
  selected?: string[],
) => {
  const defaultSelectedToggleSettingTab =
    props.data?.filter((item) => item.isActiveDefault).map((item) => item.id || '') || [];
  const toggleSettingTabs = computed(
    () =>
      props.data
        ?.map((item) => {
          return {
            value: item.id,
            title: (item.label as Lang)?.en || '',
            show:
              (item.type === 'control' && !item.hide) ||
              (item.type === 'toggleGroup' && item.controls?.find((item) => !item.hide)),
          };
        })
        .filter((item) => item.show) || [],
  );

  const toggleSettingsTabSelected = ref<string[]>(selected ?? []);

  const handleDisplaySettings = (toggleSettingIDSelected: string[]) => {
    toggleSettingsTabSelected.value = [...toggleSettingIDSelected];
    emit('onSelect', toggleSettingsTabSelected.value);
  };

  const isCanPlus = computed(() => toggleSettingsTabSelected.value.length < toggleSettingTabs.value.length);

  const toggleClearSettings = () => {
    if (!isCanPlus.value) handleDisplaySettings([]);
    else {
      toggleSettingsTabSelected.value.length === 0 && handleDisplaySettings(defaultSelectedToggleSettingTab);
    }
  };

  return {
    isCanPlus,
    toggleSettingTabs,
    toggleSettingsTabSelected,
    defaultSelectedToggleSettingTab,
    toggleClearSettings,
    handleDisplaySettings,
  };
};
