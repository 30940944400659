import { debounce, parseUnit } from '@gem/common';
import { getLabelWithLang, useI18n } from '@gem/i18n';
import { computed, nextTick, ref, watch } from 'vue';
import type { ControlInputUnitDropdownTypes } from '../type';
import { useInputValidateValue } from './useInputValidateValue';

export const useInputUnitWithDropdown = (
  props: ControlInputUnitDropdownTypes & { globalStyleContainerWidth?: any },
  emit: any,
) => {
  const { t } = useI18n({ useScope: 'global' });
  const { modifyValueByMinMax, handleNegativeValue } = useInputValidateValue(props);
  const inputEl = ref<any>();
  const dropdownRef = ref();
  const isFocus = ref(false);
  const isShowDropDownButton = ref(false);
  const isHoverButton = ref(false);
  const defaultUnit = computed(() => {
    if (props.defaultUnit) return props.defaultUnit;
    if (props.units?.length === 1) return props.units[0];
    return 'px';
  });
  const val = computed(() => props.value);
  const previousInputValue = ref<string | undefined>(props.value ? props.value : '');
  const globalStyleContainerWidth = computed<string>(() => props?.globalStyleContainerWidth || '');
  const isActive = computed(() => props.active);
  const fallbackValue = computed(() => props.fallbackValue ?? undefined);
  const stepValue = computed(() => props.step || 1);
  const placeholder = computed(() => props.placeholder || t('Auto'));

  const isEnableDropdown = computed(() => props.displayOptions?.length && isShowDropDownButton.value && !props.disable);

  const initValue = (value?: string) => {
    if (value === undefined) return undefined;
    if (value.toString()?.toLocaleLowerCase() === 'auto') return t('Auto');
    let displayValue = value;
    if (value === 'default' && globalStyleContainerWidth.value) displayValue = globalStyleContainerWidth.value;
    const [textValue, unit] = parseUnit(displayValue);
    if (props.isNumber && props.showDefaultUnit) return `${textValue}${defaultUnit.value}`;
    if (props.units?.includes(unit))
      return unit === defaultUnit.value && !props.showDefaultUnit ? textValue : `${textValue}${unit}`;
    return textValue;
  };

  const inputValue = computed(() => {
    return initValue(val.value);
  });

  const handleEmit = (type: 'change' | 'onChange', value?: string) => {
    if (type === 'change') {
      if (value === previousInputValue.value) return;
      emit('controlChange', value);
    } else {
      emit('controlOnChange', value);
    }
  };

  const handleSelect = (value: string) => {
    if (value === val.value) return;
    isShowDropDownButton.value = false;
    emit('controlChange', value);
  };

  const convertValueToNumber = (value?: string) => {
    const num = parseFloat(value ?? '0');
    if (isNaN(num)) return 0;

    if (props.disableDecimal) {
      return Math.floor(num);
    }

    if (Number.isInteger(num)) {
      return num;
    }

    return +num.toFixed(1);
  };

  const processInputValue = (value: string) => {
    if (!value || value === undefined) return fallbackValue.value;
    if (value?.toLocaleLowerCase() === 'auto') return t('Auto');

    let validValue = modifyValueByMinMax(value);
    if (!props.enableNegative) validValue = handleNegativeValue(validValue);
    const [textValue, unit] = parseUnit(validValue || value);
    let newValue = textValue;
    if (isNaN(Number(newValue))) return previousInputValue.value;
    if (newValue === 'default') return newValue;

    newValue = convertValueToNumber(newValue?.toString());

    if (props.isNumber) return newValue?.toString();

    if (props.units?.includes(unit)) return `${newValue}${unit}`;

    return `${newValue}${defaultUnit.value}`;
  };

  const handleInputChange = (value: string) => {
    const val = processInputValue(value);
    inputEl.value && inputEl.value?.updateValue(initValue(val));
    handleEmit('change', val);
  };

  const handleInputOnChange = (value: string) => {
    const val = processInputValue(value);
    handleEmit('onChange', val);
  };

  const handleUpDownValue = (e: Event, type: 'up' | 'down') => {
    e.preventDefault();
    debounce(() => {
      const [textValue, unit] = parseUnit((e.target as HTMLInputElement).value);
      const numericValue = textValue !== undefined ? Number(textValue) : 0;
      let newValue: string | number;
      newValue = type === 'up' ? numericValue + stepValue.value : numericValue - stepValue.value;
      newValue = props.disableDecimal ? Math.floor(newValue) : +newValue.toFixed(1);
      newValue = modifyValueByMinMax(newValue.toString()) || newValue;
      const value = `${newValue}${unit || defaultUnit.value}`;
      inputEl.value && inputEl.value?.updateValue(initValue(value));
      (e.target as HTMLInputElement).value = value;
      handleEmit('onChange', value);
    }, 10);
  };

  const onMouseLeave = () => {
    !dropdownRef.value?.open && (isShowDropDownButton.value = false);
  };

  const onMouseover = () => (isShowDropDownButton.value = true);

  const onMouseOverButton = () => {
    if (isFocus.value) isHoverButton.value = true;
  };

  const onMouseLeaveButton = () => {
    if (isFocus.value) isHoverButton.value = false;
  };

  watch(
    () => props.active,
    () => {
      nextTick(() => {
        if (props.active) inputEl.value?.focus();
      });
    },
  );

  watch(
    () => props.value,
    (newVal) => {
      if (!props.disablePreviousInputValue) previousInputValue.value = newVal;
    },
  );

  return {
    val,
    isFocus,
    inputEl,
    isActive,
    inputValue,
    dropdownRef,
    placeholder,
    isHoverButton,
    isEnableDropdown,
    isShowDropDownButton,
    onMouseover,
    onMouseLeave,
    handleSelect,
    handleInputChange,
    handleInputOnChange,
    handleEmit,
    handleUpDownValue,
    onMouseOverButton,
    onMouseLeaveButton,
    displayOptionsWithLang: computed(() => {
      return props.displayOptions?.map((item) => {
        return {
          ...item,
          label: getLabelWithLang(item.label),
          title: getLabelWithLang(item.title),
        };
      });
    }),
  };
};
