<script setup lang="ts">
import type { VariantPresetItemProps } from './types';
import { GButton } from '@gem/uikit-v2';
import MoreSettings from '../../sidebar-setting/MoreSettings.vue';
import {
  Corner,
  Border,
  Switch,
  Dropdown,
  BoxShadow,
  ColorPicker,
  SettingLayout,
  InputAndSlider,
  InputComboLayout,
  InputUnitWithDropdown,
} from '@gem/element-setting-ui';
import { useVariantPresetControl } from './hooks/useVariantPresetControl';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<VariantPresetItemProps>();
const emit = defineEmits(['controlChange', 'controlOnChange', 'openSwatchesModal']);

const {
  isHideOption,
  stylePresets,
  isShowBgColor,
  isShowTextColor,
  isShowGapSetting,
  currStyleSelected,
  optionsConfigured,
  isShowConfigureButton,
  isDisableShowHideButton,
  getComboProps,
  handleShowHide,
  setVariantStyle,
  handleInputChange,
  openSwatchesModal,
  getBorderStateValue,
  getCornerStateValue,
  getShadowStateValue,
  getBgColorStateValue,
  getTextColorStateValue,
  handleChangeStateSetting,
  handleOnChangeStateSetting,
} = useVariantPresetControl(props, emit);
</script>

<template>
  <SettingLayout
    :label-with-lang="{ en: `Option: ${name}` }"
    label-variant="primary"
    :disabled="isDisableShowHideButton"
    :tooltip-message="`You can't hide all option style`"
    tooltip-position="right">
    <template #control>
      <Switch :value="!isHideOption" :readonly="isDisableShowHideButton" @control-change="handleShowHide" />
    </template>
  </SettingLayout>
  <template v-if="!isHideOption">
    <SettingLayout :label-with-lang="{ en: `Style` }" align="top">
      <template #control>
        <Dropdown
          id="selectedPreset"
          :options="stylePresets"
          :value="currStyleSelected"
          :show-selected-svg="true"
          :selected-svg-width="60"
          :disable-selected-text="true"
          @control-change="setVariantStyle" />
        <GButton
          v-if="isShowConfigureButton"
          class="mt-16"
          type="secondary"
          size="medium"
          button-width="full"
          @click="() => openSwatchesModal()">
          <span v-if="currStyleSelected === 'color'">{{ t('Edit color') }}</span>
          <span v-else>{{ t('Upload image') }}</span>
        </GButton>
      </template>
    </SettingLayout>
    <SettingLayout :label-with-lang="{ en: `Width` }">
      <template #control>
        <InputUnitWithDropdown
          id="width"
          :value="optionsConfigured?.width?.[currentScreen]"
          :units="['px', '%']"
          fallback-value="Auto"
          @control-change="(value) => handleInputChange('change', 'width', value)"
          @control-on-change="(value) => handleInputChange('onChange', 'width', value)" />
      </template>
    </SettingLayout>
    <SettingLayout :label-with-lang="{ en: `Height` }">
      <template #control>
        <InputUnitWithDropdown
          id="height"
          :value="optionsConfigured?.height"
          :units="['px']"
          fallback-value="Auto"
          @control-change="(value) => handleInputChange('change', 'height', value)"
          @control-on-change="(value) => handleInputChange('onChange', 'height', value)" />
      </template>
    </SettingLayout>
    <MoreSettings data-test="group-more-settings">
      <SettingLayout v-if="isShowGapSetting" :label-with-lang="{ en: `Value gap` }">
        <template #control>
          <InputAndSlider
            id="spacing"
            :value="optionsConfigured?.spacing"
            :units="['px']"
            :min="0"
            :max="100"
            @control-change="(value) => handleInputChange('change', 'spacing', value)"
            @control-on-change="(value) => handleInputChange('onChange', 'spacing', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isShowBgColor" :label-with-lang="{ en: `Background` }">
        <template #control>
          <ColorPicker
            v-bind="getComboProps('optionBgColor')"
            id="optionBgColor"
            :value="getBgColorStateValue()"
            @control-change="(value) => handleChangeStateSetting('optionBgColor', value)"
            @control-on-change="(value) => handleOnChangeStateSetting('optionBgColor', value)" />
        </template>
      </SettingLayout>
      <SettingLayout v-if="isShowTextColor" :label-with-lang="{ en: `Text color` }">
        <template #control>
          <ColorPicker
            v-bind="getComboProps('optionTextColor')"
            id="optionTextColor"
            :value="getTextColorStateValue()"
            @control-change="(value) => handleChangeStateSetting('optionTextColor', value)"
            @control-on-change="(value) => handleOnChangeStateSetting('optionTextColor', value)" />
        </template>
      </SettingLayout>
      <SettingLayout :label-with-lang="{ en: `Border` }">
        <template #control>
          <Border
            v-bind="getComboProps('optionBorder')"
            id="optionBorder"
            :value="getBorderStateValue()"
            @control-change="(value) => handleChangeStateSetting('optionBorder', value)"
            @control-on-change="(value) => handleOnChangeStateSetting('optionBorder', value)" />
        </template>
      </SettingLayout>
      <SettingLayout :label-with-lang="{ en: `Corner` }">
        <template #control>
          <Corner
            v-bind="getComboProps('optionCorner')"
            id="optionCorner"
            :value="getCornerStateValue()"
            @control-change="(value) => handleChangeStateSetting('optionRounded', value)"
            @control-on-change="(value) => handleOnChangeStateSetting('optionRounded', value)" />
        </template>
      </SettingLayout>
      <SettingLayout :label-with-lang="{ en: `Shadow` }">
        <template #control>
          <BoxShadow
            v-bind="getComboProps('optionShadow')"
            id="optionShadow"
            :value="getShadowStateValue()"
            @control-change="(value) => handleChangeStateSetting('optionShadow', value)"
            @control-on-change="(value) => handleOnChangeStateSetting('optionShadow', value)" />
        </template>
      </SettingLayout>
      <SettingLayout :label-with-lang="{ en: `Hover` }">
        <template #control>
          <InputComboLayout
            v-bind="getComboProps('optionHoverEffect')"
            :level="1"
            :placeholder="t('Add...')"
            :label="{ en: 'Hover effect' }"
            :show-label="false"
            :content-display="'Multiple'"
            combo-icon="gp-trigger-action-hover"
            :is-full-width="true">
            <SettingLayout v-if="isShowBgColor" :label-with-lang="{ en: `Background` }">
              <template #control>
                <ColorPicker
                  v-bind="getComboProps('optionBgColorHover', 'optionHoverEffect')"
                  id="optionBgColorHover"
                  :level="2"
                  :value="getBgColorStateValue('hover')"
                  @control-change="(value) => handleChangeStateSetting('optionBgColor', value, 'hover')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionBgColor', value, 'hover')" />
              </template>
            </SettingLayout>
            <SettingLayout v-if="isShowTextColor" :label-with-lang="{ en: `Text color` }">
              <template #control>
                <ColorPicker
                  v-bind="getComboProps('optionTextColorHover', 'optionHoverEffect')"
                  id="optionTextColorHover"
                  :level="2"
                  :value="getTextColorStateValue('hover')"
                  @control-change="(value) => handleChangeStateSetting('optionTextColor', value, 'hover')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionTextColor', value, 'hover')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Border` }">
              <template #control>
                <Border
                  v-bind="getComboProps('optionBorderHover', 'optionHoverEffect')"
                  id="optionBorderHover"
                  :level="2"
                  :value="getBorderStateValue('hover')"
                  @control-change="(value) => handleChangeStateSetting('optionBorder', value, 'hover')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionBorder', value, 'hover')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Corner` }">
              <template #control>
                <Corner
                  v-bind="getComboProps('optionCornerHover', 'optionHoverEffect')"
                  id="optionCornerHover"
                  :level="2"
                  :value="getCornerStateValue('hover')"
                  @control-change="(value) => handleChangeStateSetting('optionRounded', value, 'hover')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionRounded', value, 'hover')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Shadow` }">
              <template #control>
                <BoxShadow
                  v-bind="getComboProps('optionShadowHover', 'optionHoverEffect')"
                  id="optionShadowHover"
                  :level="2"
                  :value="getShadowStateValue('hover')"
                  @control-change="(value) => handleChangeStateSetting('optionShadow', value, 'hover')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionShadow', value, 'hover')" />
              </template>
            </SettingLayout>
          </InputComboLayout>
        </template>
      </SettingLayout>
      <SettingLayout v-if="currStyleSelected !== 'dropdown'" :label-with-lang="{ en: `Active` }">
        <template #control>
          <InputComboLayout
            v-bind="getComboProps('optionActiveEffect')"
            :level="1"
            :placeholder="t('Add...')"
            :label="{ en: 'Active effect' }"
            :show-label="false"
            :content-display="'Multiple'"
            combo-icon="polaris-status-active"
            :is-full-width="true">
            <SettingLayout v-if="isShowBgColor" :label-with-lang="{ en: `Background` }">
              <template #control>
                <ColorPicker
                  v-bind="getComboProps('optionBgColorActive', 'optionActiveEffect')"
                  id="optionBgColorActive"
                  :level="2"
                  :value="getBgColorStateValue('active')"
                  @control-change="(value) => handleChangeStateSetting('optionBgColor', value, 'active')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionBgColor', value, 'active')" />
              </template>
            </SettingLayout>
            <SettingLayout v-if="isShowTextColor" :label-with-lang="{ en: `Text color` }">
              <template #control>
                <ColorPicker
                  v-bind="getComboProps('optionTextColorActive', 'optionActiveEffect')"
                  id="optionTextColorActive"
                  :level="2"
                  :value="getTextColorStateValue('active')"
                  @control-change="(value) => handleChangeStateSetting('optionTextColor', value, 'active')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionTextColor', value, 'active')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Border` }">
              <template #control>
                <Border
                  v-bind="getComboProps('optionBorderActive', 'optionActiveEffect')"
                  id="optionBorderActive"
                  :level="2"
                  :value="getBorderStateValue('active')"
                  @control-change="(value) => handleChangeStateSetting('optionBorder', value, 'active')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionBorder', value, 'active')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Corner` }">
              <template #control>
                <Corner
                  v-bind="getComboProps('optionCornerActive', 'optionActiveEffect')"
                  id="optionCornerActive"
                  :level="2"
                  :value="getCornerStateValue('active')"
                  @control-change="(value) => handleChangeStateSetting('optionRounded', value, 'active')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionRounded', value, 'active')" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: `Shadow` }">
              <template #control>
                <BoxShadow
                  v-bind="getComboProps('optionShadowActive', 'optionActiveEffect')"
                  id="optionShadowActive"
                  :level="2"
                  :value="getShadowStateValue('active')"
                  @control-change="(value) => handleChangeStateSetting('optionShadow', value, 'active')"
                  @control-on-change="(value) => handleOnChangeStateSetting('optionShadow', value, 'active')" />
              </template>
            </SettingLayout>
          </InputComboLayout>
        </template>
      </SettingLayout>
    </MoreSettings>
  </template>
</template>
