<script setup lang="ts">
import { SettingTitle } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
import IconPreview from './IconPreview.vue';

type Props = {
  listIcon: any;
  selectedItem: any;
};

defineProps<Props>();
const { t } = useI18n({ useScope: 'global' });
const emit = defineEmits<{
  (e: 'on-select', value: string, id: string, name: string): void;
}>();

const handleSelected = (value: string, id: string, name: string) => {
  emit('on-select', value, id, name);
};
</script>

<template>
  <SettingTitle :label-with-lang="t('All icons')" variant="secondary" />
  <div v-for="(items, index) in listIcon" :key="index" class="grid grid-cols-5 gap-8">
    <IconPreview
      v-for="icon in items.data"
      :key="icon.id"
      type="system"
      :icon="icon"
      :selected-item="selectedItem"
      @on-select="handleSelected" />
  </div>
</template>
