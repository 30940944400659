<script lang="ts" setup>
import { getLabel } from '../common';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import { CreationDateType } from '../constant';
import InputBadge from '../common/InputBadge.vue';
import { useCreationDate } from './hooks/useCreationDate';
import type { CreationDateEmit, CreationDateProps } from './types';
import DateTimePickers from '../../date-picker/DateTimePickers.vue';

const props = defineProps<CreationDateProps>();
const emit = defineEmits<CreationDateEmit>();

const { handleSelectDateType, handleChangeDate, message, CREATION_OPTIONS, DATE_OPTIONS, addDays } = useCreationDate(
  props,
  emit,
);
</script>

<template>
  <PopoverContent :title="t('Type')">
    <div class="w-[140px]">
      <GSelect :active-id="data.type" :options="CREATION_OPTIONS" @select="handleSelectDateType">
        <template #button-content>
          <span>{{ getLabel(data.type) }}</span>
        </template>
      </GSelect>
    </div>
  </PopoverContent>
  <PopoverContent v-if="data.type === CreationDateType.DURATION" :title="t('Days')">
    <InputBadge
      id="days"
      :value="data.days"
      :min="1"
      :options="DATE_OPTIONS"
      @on-change="(id: string, value: string) => handleChangeDate(id, value)" />
    <template #message>
      <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
        <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
        <p>{{ message }}</p>
      </div>
    </template>
  </PopoverContent>
  <PopoverContent v-else-if="data.type === CreationDateType.EXACT_DATE" :title="t('Date')">
    <DateTimePickers
      id="from"
      :is-only-accept-integer="true"
      :auto-emit-control-change="false"
      :value="data.from"
      class="w-full max-w-[140px]"
      mode="date"
      :placeholder="t('E.g: Jan 01 2024')"
      @control-change="handleChangeDate" />
  </PopoverContent>
  <template v-else>
    <PopoverContent :title="t('From')">
      <DateTimePickers
        id="from"
        :max-date="data.to ? addDays(new Date(data.to), -1) : undefined"
        :is-only-accept-integer="true"
        :value="data?.from"
        :auto-emit-control-change="false"
        class="w-full max-w-[140px]"
        mode="date"
        :placeholder="t('E.g: Jan 01 2024')"
        @control-change="handleChangeDate" />
    </PopoverContent>
    <PopoverContent :title="t('To')">
      <DateTimePickers
        id="to"
        :min-date="data.from ? addDays(new Date(data.from), 1) : undefined"
        :is-only-accept-integer="true"
        :auto-emit-control-change="false"
        :value="data?.to"
        class="w-full max-w-[140px]"
        mode="date"
        :placeholder="t('E.g: Jan 01 2024')"
        @control-change="handleChangeDate" />
      <template #message>
        <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
          <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
          <p>{{ message }}</p>
        </div>
      </template>
    </PopoverContent>
  </template>
</template>
