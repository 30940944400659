<script setup lang="ts">
import { cn, ID } from '@gem/common';
import type { LabelWithLang, SettingUIHelpType } from '@gem/element-setting-ui';
import { SettingTitle } from '@gem/element-setting-ui';
import { getLabelWithLang, useI18n } from '@gem/i18n';
import { GIcon, GSvg, type SvgName } from '@gem/icons';
import { GButton, GPopover } from '@gem/uikit-v2';
import { computed, ref, watch } from 'vue';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';

type PresetSettingProps = {
  id?: string;
  label?: LabelWithLang;
  popoverLabel?: LabelWithLang;
  icon?: SvgName;
  contentDisplay?: string;
  help?: SettingUIHelpType;
  showLabel?: boolean;
  placeholder?: string;
  isParent?: boolean;
  isChild?: boolean;
  isHideClear?: boolean;
  inputStyle?: string;
};

const { t } = useI18n({ useScope: 'global' });
const reference = ref<Element>();
const popoverReference = ref();
const isOpenMoreSetting = ref(false);

const props = withDefaults(defineProps<PresetSettingProps>(), {
  showLabel: true,
});

const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'click'): void;
}>();

const sideBarStore = useSettingSideBarStore();

const comboID = computed(() => `${props.id}-${ID()}`);
const isClosePopover = computed(
  () => sideBarStore.comboParentActiveID === comboID.value && sideBarStore.isCloseComboParentActive,
);

const handleCloseAllPopover = () => {
  sideBarStore.setComboParentRef('');
  sideBarStore.setComboActiveID('');
  sideBarStore.setComboParentActiveID('');
};

const handleClickOutside = () => {
  if (comboID.value !== sideBarStore.comboParentActiveID) return;
  handleCloseAllPopover();
};

const handleOpenPopover = () => {
  isOpenMoreSetting.value = true;
  emit('click');
  if (props.isParent) {
    sideBarStore.setComboParentActiveID(comboID.value);
    sideBarStore.setComboParentRef(reference);
    sideBarStore.setIsCloseComboParentActive(false);
  } else if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(true);
    sideBarStore.setIsOpenComboChildren(true);
    popoverReference.value.updatePosition(sideBarStore.comboParentRef);
  } else {
    sideBarStore.setComboParentActiveID(undefined);
    sideBarStore.setComboParentRef(undefined);
    sideBarStore.setIsCloseComboParentActive(true);
  }
};

const handleClosePopover = () => {
  isOpenMoreSetting.value = false;
  if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(false);
    sideBarStore.setIsOpenComboChildren(false);
  } else if (props.isParent && sideBarStore.isOpenComboChildren) {
    sideBarStore.setIsCloseComboParentActive(true);
  } else {
    sideBarStore.setComboParentActiveID(undefined);
    sideBarStore.setComboParentRef(undefined);
  }
};

const handleOpenParentPopover = () => {
  if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(false);
    popoverReference.value.close();
  }
};

watch(isClosePopover, (newValue, oldValue) => {
  if (oldValue === undefined || newValue === oldValue) return;
  newValue ? popoverReference.value.close() : popoverReference.value.onOpen();
});
</script>

<template>
  <div class="flex flex-col justify-between gap-8">
    <SettingTitle v-if="showLabel" :label-with-lang="label" variant="secondary" />
    <div ref="reference">
      <GPopover
        ref="popoverReference"
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        placement="right-start"
        :offset-top="-8"
        :ignore-outside-class="[`gp-combo-input-${comboID}`]"
        :no-padding="true"
        :placement-offset="20"
        :enable-flip="false"
        :detect-side-bar="true"
        @click-out-side="handleClickOutside"
        @close="handleClosePopover"
        @open="handleOpenPopover">
        <div class="group w-full cursor-pointer" :class="`gp-combo-input-${comboID}`">
          <div
            class="bg-dark-400 border-dark-400 text-light-200 text-12 group-hover:bg-dark-250 group-hover:border-dark-250 flex h-[64px] w-full items-center gap-8 rounded-[8px] border p-8"
            :class="cn({ '!border-primary-300': isOpenMoreSetting })">
            <div class="flex h-full w-[80px] items-center justify-center">
              <GSvg v-if="icon" :name="icon" />
              <template v-else>
                <slot name="icon" />
              </template>
            </div>
            <div class="flex h-full w-[calc(100%-108px)] items-center">
              {{ getLabelWithLang(contentDisplay) || t('Select preset') }}
            </div>
            <div
              class="w-[28px)] text-text-dark-500 absolute right-[8px] hidden h-full items-center justify-center group-hover:flex"
              :class="cn({ '!flex': isOpenMoreSetting })">
              <GIcon name="polaris-chevron-right" />
            </div>
          </div>
        </div>
        <template #content="{ close }">
          <div class="w-[280px]">
            <div class="border-dark-300 flex h-[52px] items-center justify-between border-b px-16">
              <div class="flex gap-4">
                <GButton
                  v-if="isChild"
                  only-icon="polaris-chevron-left"
                  type="ghost"
                  size="semi-medium"
                  @click="handleOpenParentPopover" />
                <SettingTitle :label-with-lang="popoverLabel" :help="help" variant="bold" />
              </div>
              <GButton
                only-icon="polaris-x"
                type="ghost"
                size="semi-medium"
                @click="!isChild ? close() : handleOpenParentPopover()" />
            </div>
            <div class="flex max-h-[calc(100vh-200px)] flex-col gap-16 py-16">
              <perfect-scrollbar>
                <div class="flex flex-col gap-16 px-16">
                  <slot></slot>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
