<script setup lang="ts">
import type { Maybe } from '@gem/common';
import { GButton } from '@gem/uikit-v2';
import ProductSourceSkeleton from './ProductSourceSkeleton.vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

type PropTypes = {
  productDisplay: {
    title: string;
    image?: Maybe<string>;
    id: Maybe<string>;
  };
  isLoading?: boolean;
  isDynamic?: boolean;
};
defineProps<PropTypes>();

const emit = defineEmits<{
  (e: 'goToProduct'): void;
}>();

const handleClick = () => {
  emit('goToProduct');
};
</script>

<template>
  <ProductSourceSkeleton v-if="isLoading" />
  <div v-else class="flex flex-col" @click="handleClick">
    <div
      class="group-hover bg-dark-400 hover:bg-dark-300 group relative h-[56px] w-full cursor-pointer overflow-hidden rounded-xl">
      <g-tooltip placement="top" :is-teleport="true" content-class="py-4" class="h-full w-full">
        <div v-if="isDynamic" class="text-12 flex items-center gap-8 rounded-xl p-8 text-white">
          <img
            alt="Custom offers"
            class="rounded-medium flex aspect-square h-40 w-40 shrink-0 overflow-hidden object-cover"
            src="../../assets/dynamic-offer.png" />
          <p>Custom offers</p>
        </div>
        <div v-else class="flex h-full w-full items-center gap-8 p-8">
          <img
            v-if="productDisplay?.image"
            class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
            :src="productDisplay?.image"
            :alt="t('product feature img')" />
          <img
            v-else
            class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
            src="../../assets/collection-thumbnail.png"
            :alt="t('product feature img')" />
          <p class="text-light-450 font-regular text-12 line-clamp-2 self-center leading-[14px]">
            {{ productDisplay?.title }}
          </p>
        </div>
        <template #content>{{ t('Manage in Product element') }}</template>
      </g-tooltip>
      <div
        class="z-5 absolute top-0 right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
        :style="{
          background: 'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
        }">
        <g-tooltip
          placement="top-end"
          :is-teleport="true"
          content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4">
          <GButton class="btn-chevron-icon" type="ghost" size="small" only-icon="polaris-chevron-right" />
          <template #content>{{ t('Manage in Product element') }}</template>
        </g-tooltip>
      </div>
    </div>
  </div>
</template>
