<script setup lang="ts">
import { useI18n } from '@gem/i18n';

defineEmits<{
  (e: 'appendItem'): void;
}>();
const { t } = useI18n({ useScope: 'global' });
</script>
<template>
  <div class="border-dark-300 sticky bottom-0 mr-14 pt-16">
    <div
      class="bg-dark-300 inline-flex h-36 w-full cursor-pointer select-none items-center justify-center rounded-xl text-white transition-colors duration-300 hover:bg-white/20"
      @click="$emit('appendItem')">
      <span class="mr-8 inline-flex">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.625 4.375C10.625 4.02982 10.3452 3.75 10 3.75C9.65482 3.75 9.375 4.02982 9.375 4.375V9.375H4.375C4.02982 9.375 3.75 9.65482 3.75 10C3.75 10.3452 4.02982 10.625 4.375 10.625H9.375V15.625C9.375 15.9702 9.65482 16.25 10 16.25C10.3452 16.25 10.625 15.9702 10.625 15.625V10.625H15.625C15.9702 10.625 16.25 10.3452 16.25 10C16.25 9.65482 15.9702 9.375 15.625 9.375H10.625V4.375Z"
            fill="white" />
        </svg>
      </span>
      <span class="text-12 text-white">{{ t('Add more') }}</span>
    </div>
  </div>
</template>
