<script setup lang="ts">
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });
</script>
<template>
  <div class="absolute top-80 left-0 flex h-[calc(100%_-_172px)] w-full flex-col items-center justify-center gap-12">
    <img src="../assets/svg-empty.png" alt="svg-empty" />
    <p class="text-12 text-text-dark-300 text-center leading-5">
      {{ t('We support icon files in SVG,') }} <br />{{ t('maximum 100KB for optimal performance.') }}
    </p>
  </div>
</template>
