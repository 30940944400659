<script setup lang="ts">
import { capitalizeSentences, cn } from '@gem/common';
import type { LabelWithLang, SettingUIHelpType } from '@gem/element-setting-ui';
import { SettingTitle } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
import { GIcon, type IconName } from '@gem/icons';
import { GButton, GInputCombo, GPopover } from '@gem/uikit-v2';
import type { StyleValue } from 'vue';
import {
  computed,
  // onBeforeUnmount,
  ref,
} from 'vue';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';

type ComboSettingProps = {
  level: number;
  comboID: string;
  comboParentID: string;
  comboRootParentID: string;
  id?: string;
  isFullWidth?: boolean;
  label?: LabelWithLang;
  comboIcon?: IconName;
  comboColor?: string;
  comboImage?: string;
  comboSvg?: string;
  contentDisplay?: string;
  help?: SettingUIHelpType;
  showLabel?: boolean;
  placeholder?: string;
  isHideClear?: boolean;
  disableClearMessage?: string;
  inputStyle?: StyleValue;
  popoverLabel?: LabelWithLang;
  disableScrollbar?: boolean;
};

const { t } = useI18n({ useScope: 'global' });
const reference = ref<Element>();
const popoverReference = ref();

const props = withDefaults(defineProps<ComboSettingProps>(), {
  showLabel: true,
  placeholder: 'Add...',
});

const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'click'): void;
  (e: 'close'): void;
}>();

const sideBarStore = useSettingSideBarStore();

const comboID = computed(() => props.comboID);
const parentID = computed(() => props.comboParentID);
const rootID = computed(() => props.comboRootParentID);
const isChild = computed(() => props.level > 1);
const displayValue = computed(() => {
  return capitalizeSentences(props.contentDisplay || '');
});
const titleMap = [
  {
    key: 'border',
    title: t('Border'),
  },
  {
    key: 'shadow',
    title: t('Shadow'),
  },
];

const parentTitle = computed(() => {
  const parentTitles = props.comboParentID.split('__');
  const parentTitle = parentTitles[parentTitles.length - 1].split('-').join(' ').toLowerCase();
  const titleInMap = titleMap.find((item) => parentTitle.includes(item.key))?.title;
  return titleInMap || parentTitle;
});

const isOpenPopover = computed(() => {
  return !!sideBarStore.comboActiveID && sideBarStore.comboActiveID === comboID.value;
});
const isActiveCompoInput = computed(() => {
  if (!!sideBarStore.comboParentActiveID && !!sideBarStore.comboActiveID) {
    if (!isChild.value) return sideBarStore.comboParentActiveID === comboID.value;
    else return sideBarStore.comboParentActiveID === comboID.value;
  }

  return false;
});

const handleCloseAllPopover = () => {
  sideBarStore.setComboParentRef('');
  sideBarStore.setComboActiveID('');
  sideBarStore.setComboParentActiveID('');
  emit('close');
};

const handleClickOutside = () => {
  if (comboID.value !== sideBarStore.comboParentActiveID) return;
  handleCloseAllPopover();
};

const handleClear = () => {
  popoverReference.value.close();
  if (!parentID.value) {
    handleCloseAllPopover();
  }
  emit('clear');
};

const handleTogglePopover = () => {
  if (comboID.value == sideBarStore.comboParentActiveID) {
    handleCloseAllPopover();
  } else if (isOpenPopover.value) {
    isChild.value ? handleOpenParentPopover() : handleCloseAllPopover();
  } else handleOpenPopover();
};

const handleOpenPopover = () => {
  emit('click');
  if (props.level === 1) {
    sideBarStore.setComboParentRef(reference);
  } else {
    popoverReference.value.updatePosition(sideBarStore.comboParentRef);
  }
  sideBarStore.setComboActiveID(comboID.value);
  sideBarStore.setComboParentActiveID(rootID.value || comboID.value);
};

const handleOpenParentPopover = () => {
  if (parentID.value) {
    sideBarStore.setComboActiveID(parentID.value);
  } else {
    sideBarStore.setComboActiveID('');
  }
};

const previewImage = computed(() => {
  if (
    props.comboImage?.includes('cdn.shopify.com') &&
    !props.comboImage.includes('.tiff') &&
    !props.comboImage.includes('.tif')
  ) {
    return props.comboImage.replace(/(_large)?(\.[^.]+)$/, '_64x64$2');
  } else {
    return props.comboImage ?? '';
  }
});

// onBeforeUnmount(() => {
//   handleCloseAllPopover();
// });
</script>

<template>
  <div data-test="combo-setting-layout" class="flex justify-between gap-8" :class="cn([!isFullWidth ? 'pl-24' : ''])">
    <SettingTitle v-if="showLabel" :label-with-lang="label" variant="secondary" />
    <div ref="reference" class="w-[140px] flex-shrink-0">
      <GPopover
        ref="popoverReference"
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        wrapper-class="gp-combo-layout"
        placement="right-start"
        :custom-toggle="false"
        :offset-top="-8"
        :overlay-container="'#sidebar'"
        :ignore-outside-class="[
          `gp-combo-input-${comboID}`,
          'gp-combo-layout',
          'popover-control-modal',
          'gallery-model-container',
        ]"
        :no-padding="true"
        :placement-offset="20"
        :enable-flip="false"
        :detect-side-bar="true"
        :force-open="isOpenPopover"
        @click-out-side="handleClickOutside"
        @click="handleTogglePopover">
        <div class="w-full" :class="`gp-combo-input-${comboID}`">
          <GInputCombo
            :value="displayValue"
            :active="isActiveCompoInput"
            :disable-clear-message="disableClearMessage"
            :prefix-icon="comboColor || comboImage || comboSvg ? undefined : comboIcon || 'polaris-plus'"
            :prefix-color="comboColor"
            :prefix-image="previewImage"
            :prefix-svg="comboSvg"
            :style="inputStyle"
            :clear-button="!isHideClear"
            @clear="handleClear" />
        </div>
        <template #content>
          <div class="w-[280px]">
            <div class="border-dark-300 flex h-[52px] items-center justify-between border-b">
              <div class="flex h-full items-center gap-8 px-8" :class="{ 'pl-[16px]': !isChild }">
                <div
                  v-if="isChild"
                  class="flex h-full cursor-pointer items-center justify-center"
                  @click="handleOpenParentPopover">
                  <GIcon name="polaris-chevron-left" :size="16" />
                </div>
                <template v-for="i in level" :key="i">
                  <SettingTitle
                    v-if="level < 3 || (level > 2 && i > level - 2)"
                    :label-with-lang="i !== level ? { en: parentTitle } : popoverLabel || label"
                    :help="help"
                    :variant="i < level ? 'secondary' : 'bold'"
                    :class="{ 'cursor-pointer': i !== level }"
                    @click="
                      () => {
                        i !== level && handleOpenParentPopover();
                      }
                    " />
                  <span v-else class="text-text-dark-300">...</span>
                  <span v-if="i < level" class="text-text-dark-300">/</span>
                </template>
              </div>
              <div class="flex h-full w-[48px] items-center justify-center">
                <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="handleCloseAllPopover" />
              </div>
            </div>
            <div class="flex max-h-[calc(100vh-200px)] flex-col gap-16 py-16">
              <template v-if="disableScrollbar">
                <div class="flex flex-col gap-16 px-16">
                  <slot></slot>
                </div>
              </template>
              <perfect-scrollbar v-else>
                <div class="flex flex-col gap-16 px-16">
                  <slot></slot>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
