<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineEmits<{
  (e: 'upload', event: Event): void;
}>();
</script>

<template>
  <div class="bg-dark-500 border-dark-300 relative mt-16 border-t pt-16">
    <GButton type="tertiary" size="medium" :button-width="'full'" :icon-before="'polaris-plus'">
      <input
        id="fontFileInput"
        class="z-5 absolute left-[-84px] m-0 h-full w-[500px] cursor-pointer opacity-0 outline-none"
        type="file"
        multiple="true"
        accept=".ttf, .otf, .woff, .woff2"
        @change="(e) => $emit('upload', e)" />
      {{ t('Upload font') }}
    </GButton>
  </div>
</template>
