<script setup lang="ts">
import type { FontItem } from '@gem/common';
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });

defineProps<{
  isShow: boolean;
  globalStyleFont?: Record<string, FontItem>;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'delete'): void;
}>();

const handleClose = () => emit('close');
</script>

<template>
  <Teleport to="body">
    <g-modal
      :is-open="isShow"
      label-ok="Delete"
      btn-ok-type="danger"
      :show-btn-close="true"
      @close="handleClose"
      @cancel="handleClose"
      @ok="$emit('delete')">
      <template #title>{{ t('Delete font') }}</template>
      <template #default>
        <div class="p-16">
          <p class="text-14 text-text-light-500 w-[568px] font-normal">
            {{ t('Are you sure you want to delete this uploaded font?') }}
          </p>
          <ul class="list-disc pl-[20px]">
            <li class="text-14 text-text-light-500 w-[568px] font-normal">
              {{ t('All heading using this font will be replaced by') }}
              <p class="inline font-semibold">{{ globalStyleFont?.heading.family }}</p>
              {{ t('- global heading font;') }}
            </li>
            <li class="text-14 text-text-light-500 w-[568px] font-normal">
              {{ t('All paragraph using this font will be replaced by') }}
              <p class="inline font-semibold">{{ globalStyleFont?.body.family }}</p>
              {{ t('- global paragraph font.') }}
            </li>
          </ul>
        </div>
      </template>
    </g-modal>
  </Teleport>
</template>
