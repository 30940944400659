<script setup lang="ts">
import { useI18n } from '@gem/i18n';

const { t } = useI18n({ useScope: 'global' });
</script>
<template>
  <div class="flex h-full w-full flex-col items-center justify-center gap-8">
    <img src="../assets/empty-search-illustration.svg" alt="" />
    <span class="text-12 text-text-dark-300 text-center"
      >{{ t('No font match your search.') }}<br />
      {{ t('Try another keyword or upload your own') }}</span
    >
  </div>
</template>
