<script lang="ts" setup>
import { SettingLayout, InputText } from '@gem/element-setting-ui';
import { createMailtoLink, parseMailto } from '../composables/parseMailto';
import { validateEmail } from '@gem/common';
import { ref, computed } from 'vue';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{ value?: string }>();
const isValidEmail = ref(true);
const val = computed(() => props.value);

const emit = defineEmits<{
  (e: 'change-mailto', value: string): void;
}>();

const dataMailto = computed(() => parseMailto(val.value || ''));
const isShowErrorEmail = computed(() => dataMailto.value.email && !isValidEmail.value);

const changeEmailLink = (key: 'email' | 'subject' | 'content', value: string) => {
  const data = { ...dataMailto.value, [key]: value };
  const link = createMailtoLink(data as any);
  emit('change-mailto', link);
};

const onValidateEmail = (value?: string) => {
  if (!value) return;
  isValidEmail.value = validateEmail(value);
};
</script>

<template>
  <SettingLayout :label-with-lang="{ en: 'Email Address' }" align="top">
    <template #control>
      <InputText
        id="email"
        :type="isShowErrorEmail ? 'error' : 'text'"
        placeholder="chris@gempages.help"
        :value="dataMailto.email"
        :error-message="isShowErrorEmail ? 'Invalid email format' : ''"
        @control-on-change="(value?: string) => onValidateEmail(value)"
        @click-out-side="(value: string) => changeEmailLink('email', value)" />
    </template>
  </SettingLayout>
  <SettingLayout :label-with-lang="{ en: 'Subject' }">
    <template #control>
      <InputText
        id="subject"
        type="text"
        :placeholder="t('Eg: Need support')"
        :value="dataMailto.subject"
        @click-out-side="(value: string) => changeEmailLink('subject', value)" />
    </template>
  </SettingLayout>
  <SettingLayout :label-with-lang="{ en: 'Messages' }">
    <template #control>
      <InputText
        id="content"
        type="text"
        :placeholder="t('Eg: Email content')"
        :value="dataMailto.content"
        @click-out-side="(value: string) => changeEmailLink('content', value)" />
    </template>
  </SettingLayout>
</template>
