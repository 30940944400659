<script setup lang="ts">
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  title?: string;
}>();

const emit = defineEmits<{
  (e: 'onBlur', value: Event | KeyboardEvent): void;
  (e: 'preventDrag', event: DragEvent): void;
  (e: 'onChangeTitle', value: string): void;
}>();
</script>
<template>
  <div
    class="bg-dark-500 flex items-center justify-between p-16"
    draggable="true"
    @dragstart="(e: DragEvent) => emit('preventDrag', e)">
    <div class="text-12 text-text-dark-300 mb-4">{{ t('Title') }}</div>
    <g-input
      class="!bg-dark-400 text-light-450 max-w-input-horizontal h-36 w-full rounded-xl border-none leading-[18px]"
      size="small"
      :value="title"
      @blur="(e: Event | KeyboardEvent) => emit('onBlur', e)"
      @on-change="(value: string) => emit('onChangeTitle', value)">
    </g-input>
  </div>
</template>
