<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineEmits<{
  (e: 'openGallery'): void;
}>();
</script>

<template>
  <div class="text-12 text-text-dark-300 mt-8 flex items-center justify-center gap-4">
    <span class="leading-6">{{ t('or') }}</span>
    <GButton type="link" size="extra-small" data-test="editor-control-btn-browse-gallery" @click="$emit('openGallery')">
      {{ t('Browse gallery') }}
    </GButton>
  </div>
</template>
