<script lang="ts" setup>
import { CompareType } from '../constant';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import InputBadge from '../common/InputBadge.vue';
import type { CreationDateProps, CreationDateEmit } from './types';
import { useDiscountPrice } from './hooks/useDiscountPrice';
import OptionValue from '../../base/OptionValue.vue';

const props = defineProps<CreationDateProps>();
const emit = defineEmits<CreationDateEmit>();

const { typeOptions, onChange, onChangeQuantityValue, min, onChangeConditionType, message } = useDiscountPrice(
  props,
  emit,
);
</script>

<template>
  <PopoverContent :title="t('Type')">
    <div class="w-[140px]">
      <OptionValue
        id=""
        :value="data.discountType"
        :options="typeOptions"
        @control-change="(value) => onChange('', value)" />
    </div>
  </PopoverContent>
  <PopoverContent :title="t('Amount')">
    <CompareTypeSelect :quantity-type="data?.type" @on-change="onChangeConditionType" />
    <RangeInput
      v-if="data?.type === CompareType.BETWEEN"
      :quantity-type="data?.type"
      :from="data.from"
      :to="data.to"
      :min="1"
      @on-update-value="onChangeQuantityValue" />
    <InputBadge v-else id="from" :value="data?.from" :min="min" @on-change="onChangeQuantityValue" />
    <template #message>
      <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
        <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
        <p>{{ message }}</p>
      </div>
    </template>
  </PopoverContent>
</template>
