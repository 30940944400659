<script setup lang="ts">
import type { BackgroundVideoProps } from './types';
import { InputComboLayout, SettingLayout, Toggle, InputText, Dropdown } from '@gem/element-setting-ui';
import { useBackgroundVideo } from './hooks/useBackgroundVideo';
import { BG_VIDEO_TYPE } from './const';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<BackgroundVideoProps>();
const emit = defineEmits<{
  (e: 'controlChange', value?: BackgroundVideoProps['value']): void;
  (e: 'controlOnChange', value?: BackgroundVideoProps['value']): void;
}>();

const { videoUrlDisplay, comboIcon, handleChangeValue, handleClear, handleSetDefaultValue } = useBackgroundVideo(
  props,
  emit,
);
</script>

<template>
  <div>
    <InputComboLayout
      :placeholder="t('Add...')"
      :label="{ en: 'Background Video' }"
      :show-label="false"
      :content-display="videoUrlDisplay"
      :combo-icon="comboIcon"
      :is-full-width="true"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClear">
      <div class="flex flex-col gap-16">
        <SettingLayout :label-with-lang="t('Source')" :label-variant="'secondary'">
          <template #control>
            <Dropdown
              id="bgAttachment"
              :value="value?.type || 'youtube'"
              :options="BG_VIDEO_TYPE"
              @control-change="(value) => handleChangeValue('type', value)">
            </Dropdown>
          </template>
        </SettingLayout>
        <SettingLayout :label="t('Video URL')" :label-variant="'secondary'">
          <template #control>
            <InputText
              v-if="value?.type === 'html5'"
              id="bgImageTitle"
              :value="value?.srcHtml5"
              :placeholder="t('E.g: https://gempages.net')"
              @control-change="(value) => handleChangeValue('srcHtml5', value)" />
            <InputText
              v-else
              id="bgImageTitle"
              :value="value?.srcYoutube"
              placeholder="E.g: https://www.youtube.com/watch?v=cyzh48XRS4M"
              @control-change="(value) => handleChangeValue('srcYoutube', value)" />
          </template>
        </SettingLayout>
        <SettingLayout :label-with-lang="{ en: 'Loop' }" :label-variant="'secondary'">
          <template #control>
            <Toggle :value="value?.loop || false" @control-change="(value) => handleChangeValue('loop', value)" />
          </template>
        </SettingLayout>
      </div>
    </InputComboLayout>
  </div>
</template>
