<script setup lang="ts">
import { inject, ref } from 'vue';
import { lineHeightOptions, textAlignOptions, transformOptions } from './const';

import { Dropdown, InputStepper, InputUnitWithDropdown, OptionValue, SettingLayout } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
import { GButton } from '@gem/uikit-v2';
import SettingTitle from '../../sidebar-setting/SettingTitle.vue';
import ColorPicker from '../color-picker/ColorPicker.vue';
import FontPicker from '../font-picker/FontPicker.vue';
import BoxShadow from '../shadow/BoxShadow.vue';
import { useTypographyFormEdit } from './hooks/useTypographyFormEdit';
import type { TypoFormEditProps } from './types';

const props = withDefaults(defineProps<TypoFormEditProps>(), {
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'saveColor', newColorList: {}): void;
  (e: 'onChange', name: string, value: any, propType?: 'custom' | 'attrs'): void;
  (e: 'change', name: string, value: any, propType?: 'custom' | 'attrs'): void;
}>();

const {
  isResultSearch,
  fontWeightValue,
  letterSpacing,
  fontSize,
  lightHeight,
  textAlign,
  isShowSettingBySearch,
  onSaveColor,
  toggleProp,
  onControlChange,
  handleChangeAttr,
  handleChange,
} = useTypographyFormEdit(props, emit);
const { t } = useI18n({ useScope: 'global' });

const collapsed = ref(true);

const { handleSaveFontMeta, handleDeleteFont, handleChangeFile, goToPricing, fontType, handleSwitchFont } = inject(
  'handle-custom-font',
) as any;
</script>
<template>
  <div class="flex flex-col gap-16" data-test="editor-control-all-font-styles">
    <div
      v-if="isShowSettingBySearch('Text Font Family')"
      class="flex items-center justify-between gap-16"
      data-test="editor-control-font-family">
      <SettingTitle :label-with-lang="t('Font')" variant="secondary" />
      <div class="max-w-input-horizontal w-full">
        <FontPicker
          v-bind="props"
          id="fontFamily"
          :hide-clear="true"
          :font-type="fontType"
          :options="fontOptions"
          :font-backup="fontBackup"
          :theme-fonts="themeFonts"
          :value="value?.fontFamily"
          :font-uploads="fontUploads"
          :global-style-font="globalStyleFont"
          :level="level || 0"
          :combo-i-d="`${comboID}-font-family`"
          :combo-parent-i-d="comboParentID || ''"
          :combo-root-parent-i-d="`${comboRootParentID}-font-family` || ''"
          @go-to-pricing="goToPricing"
          @control-change="handleChange"
          @handle-switch-font="handleSwitchFont"
          @handle-upload-font="handleChangeFile"
          @handle-delete-font="handleDeleteFont"
          @handle-save-font-meta="handleSaveFontMeta" />
      </div>
    </div>
    <div
      v-if="isShowSettingBySearch('Text Font Size')"
      class="flex items-start justify-between gap-16"
      data-test="editor-control-font-size-style">
      <SettingLayout :label-with-lang="t('Size')" :show-devices="true" align="top">
        <template #control>
          <InputStepper
            id="fontSize"
            :value="fontSize"
            @control-change="(value) => handleChange('fontSize', value)"
            @control-on-change="(value) => onControlChange('fontSize', value)" />
          <div data-test="editor-control-typo-select-style" class="text-light-450 mt-16 flex justify-between gap-8">
            <button
              v-if="!hiddenSetting?.bold"
              data-test="editor-control-typo-select-style-bold"
              class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
              :class="{
                'bg-dark-300': attrs?.bold,
              }"
              @click="toggleProp('bold')">
              <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.21387 17.5C4.66158 17.5 4.21387 17.0523 4.21387 16.5L4.21387 3.5C4.21387 2.94772 4.66158 2.5 5.21387 2.5L10.2197 2.5C11.3232 2.5 12.2437 2.66357 12.981 2.99072C13.7183 3.31787 14.2725 3.77197 14.6436 4.35303C15.0146 4.9292 15.2002 5.59326 15.2002 6.34522C15.2002 6.93115 15.083 7.44629 14.8486 7.89063C14.6143 8.33008 14.292 8.69141 13.8818 8.97461C13.4766 9.25293 13.0127 9.45068 12.4902 9.56787V9.71435C13.0615 9.73877 13.5962 9.8999 14.0942 10.1978C14.5972 10.4956 15.0049 10.9131 15.3174 11.4502C15.6299 11.9824 15.7861 12.6172 15.7861 13.3545C15.7861 14.1504 15.5884 14.8608 15.1929 15.4858C14.8022 16.106 14.2236 16.5967 13.457 16.958C12.6904 17.3193 11.7456 17.5 10.6226 17.5H5.21387ZM7.38525 14.9072L9.9707 14.9072C10.8545 14.9072 11.499 14.7388 11.9043 14.4019C12.3096 14.0601 12.5122 13.606 12.5122 13.0396C12.5122 12.6245 12.4121 12.2583 12.2119 11.9409C12.0117 11.6235 11.7261 11.3745 11.355 11.1938C10.9888 11.0132 10.5518 10.9229 10.0439 10.9229L7.38525 10.9229V14.9072ZM7.38525 8.77686H9.73633C10.1709 8.77686 10.5566 8.70117 10.8936 8.5498C11.2354 8.39356 11.5039 8.17383 11.6992 7.89063C11.8994 7.60742 11.9995 7.26807 11.9995 6.87256C11.9995 6.33057 11.8066 5.89355 11.4209 5.56152C11.04 5.22949 10.498 5.06348 9.79492 5.06348L7.38525 5.06348L7.38525 8.77686Z"
                  fill="currentColor" />
              </svg>
            </button>
            <button
              v-if="!hiddenSetting?.italic"
              data-test="editor-control-typo-select-style-italic"
              class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
              :class="{
                'bg-dark-300': attrs?.italic,
              }"
              @click="toggleProp('italic')">
              <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.48538 17.4999H2.5C2.15482 17.4999 1.875 17.22 1.875 16.8749C1.875 16.5297 2.15482 16.2499 2.5 16.2499H7.07219L11.9248 3.74986L7.5 3.74986C7.15482 3.74986 6.875 3.47004 6.875 3.12486C6.875 2.77968 7.15482 2.49986 7.5 2.49986L12.8235 2.49986C12.8331 2.49964 12.8428 2.49964 12.8524 2.49986L17.5 2.49986C17.8452 2.49986 18.125 2.77969 18.125 3.12486C18.125 3.47004 17.8452 3.74986 17.5 3.74986L13.2656 3.74986L8.41307 16.2499H12.5C12.8452 16.2499 13.125 16.5297 13.125 16.8749C13.125 17.22 12.8452 17.4999 12.5 17.4999H7.51433C7.5047 17.5001 7.49505 17.5001 7.48538 17.4999Z"
                  fill="currentColor" />
              </svg>
            </button>
            <button
              v-if="!hiddenSetting?.underline"
              data-test="editor-control-typo-select-style-underline"
              class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
              :class="{
                'bg-dark-300': attrs?.underline,
              }"
              @click="toggleProp('underline')">
              <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.625 2.5C15.625 2.15483 15.3452 1.875 15 1.875C14.6548 1.875 14.375 2.15482 14.375 2.5L14.375 10.0011C14.375 12.4171 12.4164 14.375 10 14.375C7.58358 14.375 5.625 12.4171 5.625 10.0011L5.62501 2.5C5.62501 2.15483 5.34519 1.875 5.00001 1.875C4.65483 1.875 4.37501 2.15482 4.37501 2.5L4.375 10.0011C4.375 13.1078 6.89357 15.625 10 15.625C13.1064 15.625 15.625 13.1078 15.625 10.0011L15.625 2.5Z"
                  fill="currentColor" />
                <path
                  d="M3.75 16.875C3.40482 16.875 3.125 17.1548 3.125 17.5C3.125 17.8452 3.40482 18.125 3.75 18.125H16.25C16.5952 18.125 16.875 17.8452 16.875 17.5C16.875 17.1548 16.5952 16.875 16.25 16.875H3.75Z"
                  fill="currentColor" />
              </svg>
            </button>
          </div>
        </template>
      </SettingLayout>
    </div>
    <div
      v-if="!hiddenSetting?.color && isShowSettingBySearch('Text Color')"
      class="flex items-center justify-between gap-16"
      data-test="editor-control-font-color">
      <SettingLayout :label-with-lang="t('Color')">
        <template #control>
          <ColorPicker
            v-bind="props"
            :id="'color'"
            :value="attrs?.color"
            :level="level"
            :combo-i-d="`${comboID}-color`"
            :combo-parent-i-d="comboParentID || ''"
            :combo-root-parent-i-d="`${comboRootParentID}-color` || ''"
            :compo-default-value="undefined"
            @save-my-colors="onSaveColor"
            @control-change="(value) => handleChangeAttr('color', value)"
            @control-on-change="(value) => handleChangeAttr('color', value)" />
        </template>
      </SettingLayout>
    </div>
    <div
      v-if="!hiddenSetting?.textAlign && isShowSettingBySearch('Text Align')"
      class="flex items-center justify-between gap-16"
      data-test="editor-control-text-align">
      <SettingLayout :label-with-lang="t('Align')" :show-devices="true">
        <template #control>
          <OptionValue
            id="textAlign"
            :value="textAlign ?? 'left'"
            :options="textAlignOptions"
            @control-change="(value) => handleChangeAttr('textAlign', value)" />
        </template>
      </SettingLayout>
    </div>
    <template v-if="!collapsed || isResultSearch">
      <div
        v-if="!hiddenSetting?.fontWeight && isShowSettingBySearch('Text Font Weight')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-font-weight">
        <SettingLayout :label-with-lang="t('Font weight')">
          <template #control>
            <Dropdown
              id="fontWeight"
              disable-default
              :options="fontWeightOptions"
              :value="fontWeightValue ?? ''"
              @control-change="(value) => handleChange('fontWeight', value)" />
          </template>
        </SettingLayout>
      </div>
      <div
        v-if="!hiddenSetting?.lineHeight && isShowSettingBySearch('Text Line height')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-line-height">
        <SettingLayout :label-with-lang="t('Line height')" :show-devices="true">
          <template #control>
            <InputUnitWithDropdown
              :id="'lineHeight'"
              :fallback-value="props.resetValue?.['lineHeight'] || '100%'"
              :value="lightHeight"
              :display-options="lineHeightOptions"
              :units="['px', '%']"
              :dropdown-width="'180px'"
              @control-on-change="(value?: string) => onControlChange('lineHeight', value)"
              @control-change="(value?: string) => handleChange('lineHeight', value)" />
          </template>
        </SettingLayout>
      </div>
      <div
        v-if="!hiddenSetting?.letterSpacing && isShowSettingBySearch('Text Letter spacing')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-letter-spacing">
        <SettingLayout :label-with-lang="t('Letter spacing')">
          <template #control>
            <InputUnitWithDropdown
              :id="'letterSpacing'"
              :units="['px', 'rem', 'em']"
              :value="letterSpacing"
              fallback-value="Auto"
              @control-on-change="(value) => onControlChange('letterSpacing', value)"
              @control-change="(value) => handleChange('letterSpacing', value)" />
          </template>
        </SettingLayout>
      </div>
      <div
        v-if="!hiddenSetting?.transform && isShowSettingBySearch('Text Transform')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-text-transform">
        <SettingLayout :label-with-lang="t('Transform')">
          <template #control>
            <OptionValue
              id="transform"
              :value="attrs?.transform ?? 'none'"
              :options="transformOptions"
              @control-change="(value) => handleChangeAttr('transform', value)" />
          </template>
        </SettingLayout>
      </div>
      <div
        v-if="!hiddenSetting?.textShadow && isShowSettingBySearch('Text Shadow')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-text-shadow">
        <SettingLayout :label-with-lang="t('Shadow')">
          <template #control>
            <BoxShadow
              v-bind="props"
              id="textShadow"
              :value="value?.textShadow"
              type="text-shadow"
              :level="level || 0"
              :combo-i-d="`${comboID}-textShadow`"
              :combo-parent-i-d="comboParentID || ''"
              :combo-root-parent-i-d="`${comboRootParentID}-textShadow` || ''"
              :compo-default-value="compoDefaultValue?.custom?.textShadow"
              @save-my-colors="onSaveColor"
              @control-change="(value) => handleChange('textShadow', value)"
              @control-on-change="(value) => onControlChange('textShadow', value)" />
          </template>
        </SettingLayout>
      </div>
    </template>
    <GButton
      v-if="!isResultSearch && !disableCollapse"
      data-test="editor-control-show-more-btn"
      type="secondaryGhost"
      size="medium"
      button-width="full"
      icon-view-box="0 0 16 16"
      :icon-after="collapsed ? 'polaris-chevron-down' : 'polaris-chevron-up'"
      @click="collapsed = !collapsed">
      {{ t(collapsed ? t('Show more') : t('Show less')) }}
    </GButton>
  </div>
</template>
