import { isNormalState, type RoundedSize } from '@gem/common';
import { useI18n } from '@gem/i18n';
import type { GDropdownMenuItemProps } from '@gem/uikit-v2';
import { computed, nextTick, ref } from 'vue';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';
import type { CornerRadius, CornerRadiusType, CornerSettingProps } from '../types';

export const useCornerSettings = (cornerSettingProps: CornerSettingProps, emit: any) => {
  const { t } = useI18n({ useScope: 'global' });
  const sidebarStore = useSettingSideBarStore();
  const globalStylesRadius = computed(() => sidebarStore.getGlobalStyle?.radius);
  const globalRadiusTypeList: RoundedSize[] = ['small', 'medium', 'large'];
  // const emptyValue: CornerRadius = { radiusType: 'none' };
  const squareValue: CornerRadius = {
    btrr: '0px',
    bblr: '0px',
    bbrr: '0px',
    btlr: '0px',
    radiusType: 'square',
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pillValue: CornerRadius = {
    btrr: '9999px',
    bblr: '9999px',
    bbrr: '9999px',
    btlr: '9999px',
    radiusType: 'circle',
  };
  const fixedValue: CornerRadius = {
    btrr: '8px',
    bblr: '8px',
    bbrr: '8px',
    btlr: '8px',
    radiusType: 'rounded',
  };

  const isFocus = ref(false);

  const defaultValue = computed(() => {
    return cornerSettingProps.compoDefaultValue || fixedValue;
  });

  const options: (GDropdownMenuItemProps & { settingValue: string })[] = [
    {
      title: t('Sharp'),
      value: 'square',
      icon: 'gp-corner-square',
      settingValue: '0px',
    },
    {
      title: t('Round'),
      value: 'rounded',
      icon: 'gp-corner-rounded',
      settingValue: '8px',
    },
    {
      title: t('Pill'),
      value: 'circle',
      icon: 'gp-corner-pill',
      settingValue: '9999px',
    },
  ];

  const cornerValues = computed(() => {
    const currentRadiusType = (cornerSettingProps.value?.radiusType as RoundedSize) ?? '';
    if (globalRadiusTypeList.includes(currentRadiusType) && globalStylesRadius.value) {
      const globalCornerVal = globalStylesRadius.value[currentRadiusType];
      if (globalCornerVal) {
        return {
          btrr: globalCornerVal,
          bblr: globalCornerVal,
          bbrr: globalCornerVal,
          btlr: globalCornerVal,
          radiusType: 'custom',
        };
      }
    }
    return cornerSettingProps.value;
  });

  const isLinked = computed(() => {
    return cornerValues.value?.radiusType !== 'custom';
  });

  const borderRadiusInputs = computed(() => (isLinked.value ? ['btlr'] : ['btlr', 'btrr', 'bblr', 'bbrr']));

  const isZeroValue = computed(() => {
    return (cornerValues.value?.btlr === '0' || cornerValues.value?.btlr === '0px') && isSameValue.value;
  });

  const isSameValue = computed(() => {
    return (
      cornerValues.value?.btlr === cornerValues.value?.bblr &&
      cornerValues.value?.bblr === cornerValues.value?.bbrr &&
      cornerValues.value?.bbrr === cornerValues.value?.btrr
    );
  });

  const isRoundValue = computed(() => {
    return cornerValues.value?.radiusType === 'rounded' && isSameValue.value && cornerValues.value?.btlr === '8px';
  });

  const isPillValue = computed(() => {
    return (
      ['rounded', 'circle']?.includes(cornerValues.value?.radiusType || '') &&
      isSameValue.value &&
      (cornerValues.value?.btlr === '9999px' || parseInt(cornerValues.value?.bblr || '') >= 9999)
    );
  });

  const getValueByKey = (key: string) => {
    const valueByKey = cornerValues.value?.[key as keyof CornerRadius] as string;
    if (valueByKey) {
      return valueByKey.includes('%') ? valueByKey : convertValueToNumber(valueByKey);
    }
    return valueByKey;
  };

  const convertValueToNumber = (value: string) => {
    const num = parseFloat(value);
    return isNaN(num) ? 0 : Number.isInteger(num) ? num : num.toFixed(1);
  };

  const displayComboValue = computed(() => {
    if (!cornerValues.value || cornerValues.value?.radiusType === 'none') return '';

    if (isZeroValue.value) return t('Sharp');

    if (isPillValue.value) return t('Pill');

    if (isRoundValue.value) return t('Round');

    return `${getValueByKey('btlr')}, ${getValueByKey('btrr')}, ${getValueByKey('bblr')}, ${getValueByKey('bbrr')}`;
  });

  const displayComboIcon = computed(() => {
    if (!cornerValues.value || cornerValues.value?.radiusType === 'none') return undefined;

    if (isZeroValue.value) return 'gp-corner-square';

    if (isPillValue.value) return 'gp-corner-pill';

    if (isRoundValue.value) return 'gp-corner-rounded';

    return 'gp-corner-single';
  });

  const dropdownSelected = computed(() => {
    return options.find((item) => item.title === displayComboValue.value)?.value;
  });

  const handleEmit = (data: CornerRadius | undefined, type: 'controlOnChange' | 'controlChange') => {
    if (type === 'controlChange') emit('controlChange', data);
    else emit('controlOnChange', data);
  };

  const handleClickPopover = () => {
    if (!cornerValues.value || cornerValues.value?.radiusType === 'none')
      handleEmit(defaultValue.value, 'controlChange');
    isFocus.value = false;
    nextTick(() => {
      isFocus.value = true;
    });
  };

  const handleClearCorner = () => {
    const state = cornerSettingProps.controlChangeData?.state;
    const clearValue = !isNormalState(state) ? undefined : ({ ...squareValue, radiusType: 'none' } as CornerRadius);
    handleEmit(clearValue, 'controlChange');
  };

  const toggleLinked = () => {
    const radiusType: CornerRadiusType = isLinked.value ? 'custom' : 'rounded';
    const valueTopLeft = cornerValues.value?.btlr;
    const dataChange = isLinked.value
      ? { ...cornerValues.value, radiusType }
      : {
          ...cornerValues.value,
          radiusType,
          bblr: valueTopLeft,
          bbrr: valueTopLeft,
          btlr: valueTopLeft,
          btrr: valueTopLeft,
        };
    handleEmit(dataChange, 'controlChange');
  };

  const getRadiusTypeFromValue = (val?: string) => {
    const value = val ?? '0';
    if (!isLinked.value) return 'custom';
    if (isLinked.value && parseInt(value.toString()) >= 9999) return 'circle';
    return (options.find((item) => item.value === value || item.settingValue === value)?.value ??
      cornerValues.value?.radiusType) as CornerRadiusType;
  };

  const handleUpdateCorner = (type: 'change' | 'onChange', key: string, value?: string) => {
    const findOptionItem = options.find((item) => item.value === value || item.settingValue === value);
    const radiusType = getRadiusTypeFromValue(value);
    const valueChange = findOptionItem?.settingValue ?? value ?? '0';
    const dataChange = isLinked.value
      ? {
          ...cornerValues.value,
          bblr: valueChange,
          bbrr: valueChange,
          btlr: valueChange,
          btrr: valueChange,
          radiusType,
        }
      : {
          ...cornerValues.value,
          [key]: valueChange,
          radiusType,
        };
    if (type === 'onChange') {
      handleEmit(dataChange, 'controlOnChange');
    } else handleEmit(dataChange, 'controlChange');
  };

  const onChangeInput = (key: string, value?: string) => {
    handleUpdateCorner('onChange', key, value);
  };

  const changeInput = (key: string, value?: string) => {
    handleUpdateCorner('change', key, value);
  };

  return {
    cornerValues,
    isLinked,
    isFocus,
    displayComboValue,
    displayComboIcon,
    options,
    dropdownSelected,
    borderRadiusInputs,
    handleClickPopover,
    handleClearCorner,
    toggleLinked,
    changeInput,
    onChangeInput,
  };
};
