<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { useImageSelectContext } from '../hooks/useImageSelectContext';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  isInvalidSrc: boolean;
}>();

const { actions, state } = useImageSelectContext();

const { imageSrc, isUploading } = state;
const { handleClearUrl, handleChangeFileFromUploader } = actions;
</script>

<template>
  <div
    v-if="!isUploading"
    data-test="editor-control-btn-upload-image"
    class="custom-upload-image-file absolute flex h-full w-full cursor-pointer items-center justify-between"
    :class="{
      'from-dark-400/80 via-dark-400/20 to-dark-400/80 invisible group-hover:bg-gradient-to-b':
        imageSrc && !isInvalidSrc,
    }">
    <input
      v-if="!imageSrc"
      id="input"
      class="z-5 absolute m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
      type="file"
      accept="image/*"
      @change.stop="handleChangeFileFromUploader" />
    <div class="dark relative flex h-full w-full">
      <div
        v-if="imageSrc && !isInvalidSrc"
        class="absolute right-4 top-4 z-[7] flex h-32 w-32 shrink-0 items-center justify-center border-none">
        <GButton type="dangerGhost" size="medium" only-icon="polaris-delete" @click="handleClearUrl" />
      </div>
      <label for="input" class="absolute top-0 z-[3] h-full w-full cursor-pointer" />
      <div
        class="z-5 absolute left-[50%] flex h-36 w-full translate-x-[-50%] cursor-pointer justify-center text-white"
        :class="{
          'bottom-[12px]': imageSrc,
          'top-[50%] translate-y-[-50%] !bg-transparent': !imageSrc || (imageSrc && isInvalidSrc),
        }">
        <GButton :type="imageSrc && !isInvalidSrc ? 'secondary' : 'ghost'" size="medium" icon-before="polaris-upload">
          <input
            id="input"
            class="z-5 absolute m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
            type="file"
            accept="image/*"
            @change.stop="handleChangeFileFromUploader" />
          <span :class="imageSrc ? 'text-light-100' : 'text-dark-light'">{{ t('Upload Image') }}</span>
        </GButton>
      </div>
    </div>
  </div>
</template>
