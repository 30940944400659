<script setup lang="ts">
import type { ColorSuggestions } from '@gem/common';
import type { ColorPickerProps } from '../types';
import ColorOnly from './ColorOnly.vue';
import InputColor from './InputColor.vue';
import ColorList from './ColorList.vue';
import { useColorPicker } from '../hooks/useColorPicker';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<ColorPickerProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: string): void;
  (e: 'controlOnChange', controlId: string, value?: string): void;
  (e: 'saveMyColors', value?: ColorSuggestions): void;
}>();

const {
  openColorPicker,
  composeColors,
  showTransparentColor,
  shouldReRenderDueToInputChange,
  myColors,
  suggestColors,
  options,
  onToggleColorPicker,
  onChangeSolidColor,
  changeSolidColor,
  changeColor,
  onChangeColor,
} = useColorPicker(props, emit);
</script>

<template>
  <div class="flex flex-col gap-16">
    <InputColor
      :value="composeColors"
      :show-transparent-color="showTransparentColor"
      :open-color-picker="openColorPicker"
      @toggle-color-picker="onToggleColorPicker"
      @on-change="onChangeSolidColor"
      @change="changeSolidColor" />
    <ColorOnly
      v-if="openColorPicker"
      :key="shouldReRenderDueToInputChange.toString()"
      :value="value"
      :is-auto-update-value="false"
      @change-color="changeColor"
      @on-change-color="onChangeColor" />
    <ColorList
      :title="t('Recent colors')"
      :colors="myColors"
      :color="options.color"
      @on-pick-color="changeSolidColor" />
    <ColorList
      :title="t('Suggested for you')"
      :colors="suggestColors"
      :color="options.color"
      @on-pick-color="changeSolidColor" />
  </div>
</template>
