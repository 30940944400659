<script setup lang="ts">
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  searchValue: string;
}>();
</script>
<template>
  <div class="flex h-full flex-col items-center justify-center">
    <img alt="" src="../assets/empty_icon.png" />
    <p class="text-12 text-text-dark-300 text-center">
      {{ t('We couldn’t find any matches for') }}
      <span class="line-clamp-2 text-light-200">"{{ searchValue }}".</span>
      {{ t('Please check your search for any typos or') }}<br />
      {{ t('try different keyword.') }}
    </p>
  </div>
</template>
