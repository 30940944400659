<script lang="ts" setup>
import type { WhereInput } from '@gem/common';
import { useColorOnly } from '../hooks/useColorOnly';
import type { ColorOnlyProps } from '../types';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = withDefaults(defineProps<ColorOnlyProps>(), {
  hexClass: 'text-white',
  alphaClass: 'text-white',
  colorSvgEyeDropper: '#E2E2E2',
  bgHoverSvgEyeDropper: 'hover:bg-dark-50',
  variant: false,
});

const emit = defineEmits<{
  (e: 'change-color', input: string, whereInput?: WhereInput): void;
  (e: 'on-change-color', input: string, whereInput?: WhereInput): void;
}>();

const {
  picker,
  surface,
  huePicker,
  alphaPicker,
  hueArea,
  alphaArea,
  alphaPickerStyle,
  pickerStyle,
  hueStyle,
  alphaStyle,
  surfaceStyle,
  borderColor,
  noHashtagHexColor,
  onChangeHexValue,
  onBlurHexInput,
  stopPropagation,
  disableShortcutDelete,
} = useColorOnly(props, emit);
</script>
<template>
  <div class="flex flex-col gap-16">
    <div v-if="variant" class="flex gap-8">
      <div class="bg-light-300 rounded-medium flex h-[36px] w-full items-center justify-start p-8">
        <div v-if="value && value !== 'transparent'" class="h-17 w-17 relative rounded-full">
          <div
            class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[17px] w-[17px] -translate-y-1/2 transform rounded-full border"
            :style="{
              'border-color': borderColor,
            }" />
          <div
            :style="{
              'background-color': value,
              'border-color': borderColor,
            }"
            class="absolute top-1/2 left-1/2 h-[17px] w-[17px] -translate-y-1/2 transform rounded-full border"></div>
        </div>
        <input
          :value="noHashtagHexColor"
          class="gemx-hex-input text-12 bg-light-300 h-full w-full pl-[28px] outline-none"
          :class="hexClass"
          @input="onChangeHexValue"
          @blur="onBlurHexInput"
          @mousedown="stopPropagation"
          @keydown="disableShortcutDelete" />
      </div>
    </div>
    <div class="flex flex-col gap-12">
      <div class="my-4 h-[127px]">
        <div ref="surface" :style="surfaceStyle" class="rounded-medium relative h-full cursor-crosshair">
          <div class="gemx-picking-bg-1 rounded-medium absolute inset-0"></div>
          <div class="gemx-picking-bg-2 rounded-medium absolute inset-0"></div>
          <div
            ref="picker"
            class="gemx-pick-surface z-10 aspect-square w-16 cursor-move rounded-full border border-white will-change-transform"
            :style="pickerStyle"></div>
        </div>
      </div>
      <div class="my-4 flex items-center">
        <div class="flex w-full flex-col gap-16">
          <div ref="hueArea" :aria-label="t('Hue')" class="gemx-hue relative h-8 cursor-pointer rounded-full">
            <div
              ref="huePicker"
              :style="hueStyle"
              class="absolute -inset-y-4 aspect-square w-16 cursor-ew-resize rounded-full border border-white"></div>
          </div>
          <div v-if="!variant" ref="alphaArea" class="gemx-transparent-bg h-8 rounded-full">
            <div class="relative h-full cursor-pointer rounded-full" :style="alphaStyle">
              <div
                ref="alphaPicker"
                :style="alphaPickerStyle"
                class="absolute -inset-y-4 aspect-square w-16 cursor-ew-resize rounded-full border border-white"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gemx-picking-bg-1 {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.gemx-picking-bg-2 {
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.gemx-hue {
  background-image: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);
}

.gemx-alpha-bg {
  background-image: url('../../assets/opacity.png');
  background-size: cover;
}

.gemx-transparent-bg {
  background-image: url('../../../assets/transparent.png');
  background-size: 80px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
