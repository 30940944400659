<script setup lang="ts">
import { GButton, GTooltip } from '@gem/uikit-v2';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  hasHover?: boolean;
  isEditable?: boolean;
  isDisabledDelete?: boolean;
}>();

const emit = defineEmits<{
  (e: 'edit'): void;
  (e: 'duplicate'): void;
  (e: 'show-delete-action'): void;
}>();
</script>

<template>
  <div class="non-draggable relative z-10 flex h-full flex-shrink-0 items-center justify-center hover:cursor-pointer">
    <GButton
      v-if="isEditable"
      type="ghost"
      only-icon="polaris-edit"
      icon-view-box="0 0 16 16"
      size="normal"
      @click.stop="emit('edit')" />
    <GButton
      type="ghost"
      only-icon="polaris-duplicate"
      icon-view-box="0 0 16 16"
      size="normal"
      @click.stop="emit('duplicate')" />
    <GTooltip placement="top" :is-teleport="true" :disabled="!isDisabledDelete">
      <GButton
        type="dangerGhost"
        only-icon="polaris-delete"
        icon-view-box="0 0 16 16"
        size="normal"
        :disable="isDisabledDelete"
        @click.stop="emit('show-delete-action')" />
      <template #content>{{ t('You need at least 1 item in the list') }}</template>
    </GTooltip>
  </div>
</template>
