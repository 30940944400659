<script setup lang="ts">
import { PLAN_NAME_MAP, type PlanPageType } from '@gem/common';
import { computed } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import { GIcon } from '@gem/icons';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  label: String;
  nearestSupportedPlan: PlanPageType;
  control?: string;
}>();

const upgradeText = computed(() => {
  return props.nearestSupportedPlan ? `Upgrade to ${PLAN_NAME_MAP[props.nearestSupportedPlan]} plan` : `Upgrade plan`;
});
const settingSideBarStore = useSettingSideBarStore();
const handleGoToPricing = () => {
  settingSideBarStore.setGoToPricing(props.control);
};
</script>

<template>
  <div class="flex items-center">
    <slot name="label"></slot>
    <div class="ml-8 flex h-20 w-24 items-center justify-center rounded-[8px] bg-yellow-300">
      <GIcon name="polaris-lock" :size="16" />
    </div>
  </div>
  <div class="text-text-dark-300 text-12">
    <div target="_blank" class="font-semi-bold text-primary-200 cursor-pointer" @click="handleGoToPricing">
      {{ upgradeText }}
    </div>
    {{ t('to unlock {label} & other premium features.') }}
  </div>
</template>
