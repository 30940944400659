<script setup lang="ts">
import { Dropdown, InputText } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  options: any[];
  styleIcon?: string;
  searchValue?: string;
}>();

const emit = defineEmits<{
  (e: 'on-select', value: string): void;
  (e: 'on-search', value: string): void;
  (e: 'clear-search'): void;
}>();
</script>
<template>
  <div class="flex items-center gap-8" data-test="control-icon-picker-filter-bar">
    <div class="w-full max-w-[108px]">
      <Dropdown
        id="style"
        :options="options"
        :value="styleIcon ?? 'ALL'"
        :placement="'bottom-start'"
        @control-change="(value) => emit('on-select', value)" />
    </div>
    <InputText
      :value="searchValue"
      type="text"
      :placeholder="t('Search icon')"
      data-test="control-icon-picker-filter-bar-input"
      prefix-icon="polaris-search"
      :clear-button="true"
      @clear="emit('clear-search')"
      @change="(value: string) => emit('on-search', value)"
      @on-change="(value: string) => emit('on-search', value)" />
  </div>
</template>
