import type { ColorSuggestions, ShadowProps, ShadowType } from '@gem/common';
import { checkEmptyValue, isNormalState } from '@gem/common';
import { useI18n } from '@gem/i18n';
import { computed } from 'vue';
import { colorToHex, isLinearGradient, isTransparent } from '../color-picker/helpers';
import type { RGBColorString } from '../color-picker/types';
import type { ShadowSettingProps } from './types';

export const useBoxShadowSettings = (shadowSettingProps: ShadowSettingProps, emit: any) => {
  const { t } = useI18n({ useScope: 'global' });
  const isTextShadow = computed(() => shadowSettingProps?.type === 'text-shadow');

  const initialShadowValue: ShadowProps = {
    type: isTextShadow.value ? 'custom' : 'shadow-2',
    distance: isTextShadow.value ? '2px' : '12px',
    blur: isTextShadow.value ? '20px' : '12px',
    spread: '0px',
    color: isTextShadow.value ? 'rgba(18, 18, 18, 0.5)' : 'rgba(18, 18, 18, 0.2)',
    angle: isTextShadow.value ? 135 : 45,
    enable: true,
  };

  const emptyShadowValue: ShadowProps = {
    type: isTextShadow.value ? 'custom' : 'shadow-2',
    distance: isTextShadow.value ? '2px' : '12px',
    blur: isTextShadow.value ? '8px' : '12px',
    spread: '0px',
    color: isTextShadow.value ? 'rgba(18, 18, 18, 0.5)' : 'rgba(18, 18, 18, 0.2)',
    angle: isTextShadow.value ? 135 : 45,
    enable: false,
  };

  const shadowPresets: {
    label: string;
    value: string;
    defaultValue?: ShadowProps;
    iconName?: string;
  }[] = [
    {
      value: 'shadow-1',
      label: t('Light'),
      iconName: 'gp-shadow-light',
      defaultValue: {
        type: 'shadow-1',
        distance: '4px',
        blur: '4px',
        spread: '0px',
        color: 'rgba(18, 18, 18, 0.2)',
        angle: 45,
      },
    },
    {
      value: 'shadow-2',
      label: t('Medium'),
      iconName: 'gp-shadow-medium',
      defaultValue: {
        type: 'shadow-2',
        distance: '12px',
        blur: '12px',
        spread: '0px',
        color: 'rgba(18, 18, 18, 0.2)',
        angle: 45,
      },
    },
    {
      value: 'shadow-3',
      label: t('Big'),
      iconName: 'gp-shadow-hard',
      defaultValue: {
        type: 'shadow-3',
        distance: '20px',
        blur: '20px',
        spread: '0px',
        color: 'rgba(18, 18, 18, 0.2)',
        angle: 45,
      },
    },
    {
      value: 'custom',
      label: t('Custom'),
      iconName: 'gp-customize',
    },
  ];

  const val = computed(() => shadowSettingProps?.value);

  const defaultValue = computed(() => {
    return shadowSettingProps.compoDefaultValue || initialShadowValue;
  });

  const isCustomShadow = computed(() => {
    return val.value?.type === 'custom';
  });

  const isEnableShadow = computed(() => {
    return val.value?.enable || val.value?.enable === undefined;
  });

  const displayComboValue = computed(() => {
    if (!isEnableShadow.value) return '';

    if (isCustomShadow.value) {
      const displayColorString = val.value?.color?.toLocaleLowerCase();

      if (isLinearGradient(displayColorString ?? '')) {
        return 'Gradient';
      }

      if (displayColorString?.startsWith('rgb')) {
        if (isTransparent(displayColorString as RGBColorString)) {
          return 'Transparent';
        }

        return colorToHex(displayColorString as RGBColorString, { ignoreAlpha: true })
          ?.replace('#', '')
          .toUpperCase();
      }

      return displayColorString?.replace('#', '').toUpperCase();
    }

    return shadowPresets.find((item) => item.value === val.value?.type)?.label ?? '';
  });

  const selectedOption = computed(() => {
    return shadowPresets.find((item) => item.value === val.value?.type);
  });

  const displayComboIcon = computed(() => {
    if (!isEnableShadow.value) return '';

    return selectedOption.value?.iconName;
  });

  const displayComboColor = computed(() => {
    if (!isEnableShadow.value) return '';

    return isCustomShadow.value ? val.value?.color : '';
  });

  const handleEmit = (data: ShadowProps | undefined, type: 'controlOnChange' | 'controlChange') => {
    if (type === 'controlChange') emit('controlChange', data);
    else emit('controlOnChange', data);
  };

  const handleUpdateSetting = (
    key: 'type' | 'color' | 'angle' | 'distance' | 'blur' | 'spread',
    type: 'controlOnChange' | 'controlChange',
    value?: string,
  ) => {
    if (!value) return;
    let data: ShadowProps = {};
    switch (key) {
      case 'type': {
        const defaultValueByType = shadowPresets.find((item) => item.value === value);
        data =
          defaultValueByType && defaultValueByType.defaultValue
            ? { ...defaultValueByType.defaultValue, color: val.value?.color ?? defaultValueByType.defaultValue.color }
            : { ...val.value, type: value as ShadowType };
        break;
      }
      case 'color':
        data = { ...val.value, color: value };
        break;
      case 'angle':
        data = { ...val.value, angle: value };
        break;
      case 'distance':
        data = { ...val.value, distance: value };
        break;
      case 'blur':
        data = { ...val.value, blur: value };
        break;
      case 'spread':
        data = { ...val.value, spread: value };
        break;
      default:
        break;
    }
    handleEmit({ ...data, enable: true }, type);
  };

  const handleClearShadow = () => {
    const state = shadowSettingProps.controlChangeData?.state;
    const clearValue = !isNormalState(state) ? undefined : emptyShadowValue;
    handleEmit(clearValue, 'controlChange');
  };

  const handleSetDefaultValue = () => {
    if (!isEnableShadow.value || checkEmptyValue(val.value)) {
      handleEmit(defaultValue.value, 'controlChange');
    }
  };

  const handleUpdateSuggestionColor = (value?: ColorSuggestions) => {
    emit('updateColorSuggestion', value);
  };

  return {
    isTextShadow,
    shadowValue: val,
    displayComboIcon,
    displayComboColor,
    displayComboValue,
    shadowPresets,
    isCustomShadow,
    handleUpdateSetting,
    handleClearShadow,
    handleSetDefaultValue,
    handleUpdateSuggestionColor,
  };
};
