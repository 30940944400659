<script setup lang="ts">
import { GButton } from '@gem/uikit-v2';
import { useI18n } from '@gem/i18n';
const { t } = useI18n({ useScope: 'global' });

defineProps<{
  inputRef: any;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'upload-file', value: any): void;
}>();
</script>
<template>
  <div class="border-dark-400 relative -mx-16 mt-auto max-h-[64px] border-t px-16 pt-16">
    <GButton type="tertiary" size="medium" icon-before="polaris-plus" button-width="full" :loading="loading">
      <input
        ref="inputRef"
        type="file"
        accept="image/svg+xml"
        class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
        @change="(value: any) => emit('upload-file', value)" />
      {{ t('Upload .SVG icon') }}
    </GButton>
  </div>
</template>
