<script setup lang="ts">
import { GButton, GDropdownMenu, GInput, GPopover } from '@gem/uikit-v2';
import { useInputUnitWithDropdown } from './hooks/useInputUnitWithDropdown';
import type { ControlInputUnitDropdownTypes } from './type';

const props = defineProps<ControlInputUnitDropdownTypes & { globalStyleContainerWidth?: any }>();
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
}>();

const {
  val,
  isFocus,
  inputEl,
  isActive,
  inputValue,
  dropdownRef,
  placeholder,
  isHoverButton,
  isEnableDropdown,
  isShowDropDownButton,
  onMouseover,
  onMouseLeave,
  handleSelect,
  handleInputChange,
  handleInputOnChange,
  handleUpDownValue,
  onMouseOverButton,
  onMouseLeaveButton,
  displayOptionsWithLang,
} = useInputUnitWithDropdown(props, emit);
</script>

<template>
  <div
    class="bg-dark-400 group relative rounded-xl border border-transparent"
    :class="{ 'border-primary-300': isFocus || isHoverButton }"
    @mouseover="onMouseover"
    @mouseleave="onMouseLeave">
    <div class="flex h-36 items-center justify-between rounded-xl" :class="{ 'group-hover:bg-dark-250': !isFocus }">
      <GInput
        v-bind="props"
        ref="inputEl"
        :align="align ?? 'left'"
        :value="inputValue"
        :is-unit="true"
        :disable="disable"
        :active="isActive"
        :no-background="true"
        no-border="all"
        :placeholder="placeholder"
        @focus="
          () => {
            isFocus = true;
            isShowDropDownButton = true;
          }
        "
        @focusout="() => (isFocus = false)"
        @keydown.up="(e: Event) => handleUpDownValue(e, 'up')"
        @keydown.down="(e: Event) => handleUpDownValue(e, 'down')"
        @click-out-side="handleInputChange"
        @on-change="handleInputOnChange" />
      <div v-if="isEnableDropdown" class="absolute top-0 right-0 cursor-pointer">
        <GPopover
          ref="dropdownRef"
          :has-arrow="false"
          :closeable="true"
          :overlay="false"
          :offset-top="-2"
          :z-index="9999"
          overlay-container="#root-modal"
          placement="bottom-end"
          cls="!p-0"
          @open="() => (isFocus = true)"
          @close="
            () => {
              isFocus = false;
              isShowDropDownButton = false;
            }
          ">
          <template #default="{ open }">
            <GButton
              tabindex="-1"
              type="dropdownInput"
              :size="dropdownButtonSize ? dropdownButtonSize : 'medium'"
              only-icon="polaris-caret-down"
              :active="open"
              no-rounded="left"
              @mouseover="onMouseOverButton"
              @mouseleave="onMouseLeaveButton" />
          </template>
          <template #content="{ close }">
            <GDropdownMenu
              :width="dropdownWidth || '200px'"
              :items="displayOptionsWithLang || []"
              :show-checked-icon="true"
              :is-multi-select="false"
              :selected="dropdownSelected || [val]"
              :more-action="dropdownMoreAction"
              @on-select="
              (value: string[]) => {
                close();
                handleSelect(value[0]);
              }
            " />
          </template>
        </GPopover>
      </div>
    </div>
  </div>
</template>
